import Vue from 'vue'
import Vuex from 'vuex'
import i18n from '../i18n'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    taxonomy: null,
    language: '',
    currentRecommendationPath: ''
  },
  mutations: {
    setTaxonomy (state, taxonomy) {
      state.taxonomy = taxonomy
    },
    SET_LANGUAGE (state, payload){
       state.language = payload
       localStorage.setItem('lang', payload)
       i18n.locale = payload
    },
    SET_CURRENT_RECOMMENDATION_PATH(state, payload){
      state.currentRecommendationPath = payload
    }
  },
  actions: {
  },
  modules: {
  },
  getters: {
    getTaxonomy: state => state.taxonomy
  }
})
