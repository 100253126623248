<template>
  <div ref="langSelector" class="nav-wrapper">
    <div class="sl-nav">
      <div @click="visible = !visible" class="nav-button">
        <img class="lang-icon" :src="require('@/assets/icons/world.svg')" />
        <b class="lang">{{ currentLang }}</b>
        <img class="arrow" :src="require('@/assets/icons/arrow.svg')" />
      </div>
      <div v-if="visible" class="lang-list">
        <div
          @click="changeLang(lang.value)"
          class="lang-item"
          v-for="lang in langs"
          :key="lang.value"
        >
          <!-- <img class="lang-icon" :src="require(`@/assets/icons/${lang.value}.svg`)" /> -->
          <span>{{ lang.label }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { allowLangs } from "../i18n";

export default {
  computed: {
    ...mapState(["language"]),
    langs() {
      return allowLangs;
    },
    currentLang() {
      return this.langs.find((el) => el.value === this.language)?.label;
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    ...mapMutations(["SET_LANGUAGE"]),
    handleClickOutside(ev) {
      if (!this.$refs.langSelector.contains(ev.target)) {
        this.visible = false;
      }
    },
    changeLang(lang) {
      this.visible = false;
      this.$i18n.locale === lang;
      const route = this.$route;
      if (this.language !== lang) {
        this.SET_LANGUAGE(lang);
        this.$router.replace(
          "/" +
            lang +
            "/" +
            route.fullPath.split("/").slice(2, route.fullPath.length).join("/")
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sl-nav {
  width: 160px;
  position: relative;
  .nav-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #00000033;
    color: white;
    padding: 4px 15px;
    border-radius: 5px;
    cursor: pointer;
    .lang-icon {
    }
    .lang {
      font-weight: 500;
      font-size: 14px;
      font-family: ubuntu;
    }
    .arrow {
      transform: scale(0.8);
    }
  }
  .lang-list {
    cursor: pointer;
    z-index: 999;
    position: absolute;
    background-color: white;
    margin-top: 7px;
    border-radius: 3px;
    width: 120%;
    max-width: 350px;
    right: 0;
    flex-direction: column;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    .lang-item {
      width: 100%;

      padding: 10px 20px;
      border-bottom: 0.5px solid #a5a5a5;
      font-weight: 400;
      color: #464647;
      display: flex;
      gap: 10px;
      &:last-child {
        border-bottom: initial;
      }
      &:hover {
        background-color: #f1f1f1;
      }
    }
    &:after {
      content: "";
      position: absolute;
      top: -9px;
      right: 15px;
      border-width: 0 10px 10px;
      border-style: solid;
      border-color: transparent transparent white;
    }
  }
}
</style>
