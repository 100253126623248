import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, BootstrapVueIcons, BIcon} from 'bootstrap-vue'
import { format } from 'date-fns'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueMq from 'vue-mq'
import VueDragscroll from 'vue-dragscroll'
import VueGtag from "vue-gtag";

import i18n from './i18n'
import router from './router'
import store from './store'
import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { faSearch,
         faTimes,
         faPlus,
         faMinus,
         faQuestionCircle,
         faEllipsisH,
         faThLarge,
         faBars,
         faExternalLinkAlt,
         faAngleRight,
         faHome,
         faFilter,
         faChevronLeft,
         faChevronRight,
         faChevronDown,
         faChevronUp,
         faTimesCircle,
         faBorderAll,
         faCaretRight,
         faCaretDown,
  } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(
  faSearch,
  faTimes,
  faPlus,
  faMinus,
  faQuestionCircle,
  faEllipsisH,
  faThLarge,
  faBars,
  faExternalLinkAlt,
  faAngleRight,
  faHome,
  faFilter,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faTimesCircle,
  faBorderAll,
  faCaretRight,
  faCaretDown
)

dom.watch()
Vue.component('font-awesome-icon', FontAwesomeIcon)
// console.log('VUE_APP_API_URL', process.env.VUE_APP_API_URL)
Vue.use(BootstrapVue)
Vue.use(VueDragscroll)
const isProduction = process.env.NODE_ENV === 'production'
Vue.use(VueGtag,{
  config: { 
    id: "G-Y5E55L6Q9Q",
    enabled: isProduction,
  }
},
router
)
Vue.use(BootstrapVueIcons)
Vue.component(BIcon)
Vue.use(VueMq, {
  breakpoints: {
    mobile: 768,
    desktop: Infinity
  }
})

Vue.config.productionTip = false
Vue.filter('formatDate', function (value) {
  if (value) {
    return format(value, 'dd MMMM yyyy')
  }
})

Vue.filter('pluralize', function (value, singular, plural) {
  if (value === 1) {
    return singular
  }
  return plural
})

Vue.filter('capitalize', function (value) {
  if (!value) {
    return ''
  }
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
