var get_year_range = function (range) {
  var today = new Date();
  // var current_year = today.getFullYear()
  today.setMonth(today.getMonth() - (range * 12 - 7));
  return `${today.getFullYear()}`;
  // return `${today.getFullYear()}-${current_year}`
};

const lastYear = () => get_year_range(1);
const lastTwoYears = () => get_year_range(2);
const lastfiveYears = () => get_year_range(5);

const messages = {
  en: {
    general: {
      doc_title: 'BIGG-REC - GRADE Recommendations',
      home: 'Home',
      map: 'Map',
      year: 'Year',
      clear: 'Clear',
      institutions: {
        WHO: 'World Health Organization',
        PAHO: 'Pan American Health Organization'
      },
      recommendation: 'Recommendation | Recommendations',
      guideline: 'Guideline | Guidelines',
      direction: 'Direction',
      rec_strength: 'Recommendation strength',
      rec_type_labels: {
        strong: 'Strong',
        conditional: 'Conditional',
        recommended: 'Recommended',
        not_recommended: 'Not recommended',
        research_context: 'Recommended in the context of rigorous research',
        context_specific: 'Recommended in specific contexts',
        targeted_monitoring_context:
          'Recommended in the context of targeted monitoring and evaluation',
        mec_1: 'MEC 1',
        mec_2: 'MEC 2',
        mec_3: 'MEC 3',
        mec_4: 'MEC 4'
      },
      rec_type_tooltips: {
        strong:
          'A recommendation can be strong or conditional. When a recommendation is strong, most people will want to follow it. When a recommendation is conditional, the majority of people want to follow it but may need more discussion with their healthcare professional first',
        conditional:
          'A recommendation can be strong or conditional. When a recommendation is strong, most people will want to follow it. When a recommendation is conditional, the majority of people want to follow it but may need more discussion with their healthcare professional first',
        recommended:
          'This category indicates that the intervention or option should be implemented. This category is not official in the GRADE approach, it constitutes part of  an alternative classification defined by the guideline development group and approved by the guideline review comitee',
        not_recommended:
          'This category indicates that the intervention or option should not be implemented.This category is not official in the GRADE approach, it constitutes part of  an alternative classification provided by the guideline development group and approved by the guideline review comitee',
        context_specific_recommendation:
          'This category indicates that the intervention or option is applicable only to the condition, setting or population specified in the recommendation, and should only be implemented in these contexts',
        research_context_recommendation:
          'This category indicates that there are important uncertainties about the intervention or option. In such instances, implementation can still be undertaken on a large scale, provided that it takes the form of research that is able to address unanswered questions and uncertainties related both to effectiveness of the intervention or option, and its acceptability and feasibility. This category is not official in the GRADE approach, it constitutes part of  an alternative classification defined by the guideline development group and approved by the guideline review comitee',
        recommended_in_the_context_of_targeted_monitoring_and_evaluation:
          'This rating indicates uncertainty about the effectiveness or acceptability of an intervention, especially with regard to particular contexts or conditions. This category is not official in the GRADE approach, it constitutes part of  an alternative classification defined by the guideline development group and approved by the guideline review comitee',
        mec_category_1:
          'This category is not official in the GRADE approach, it constitutes part of  an alternative classification defined by the guideline development group and approved by the guideline review comitee. This category indicates a condition for which there is no restriction for the use of the contraceptive method',
        mec_category_2:
          'This category is not official in the GRADE approach, it constitutes part of  an alternative classification defined by the guideline development group and approved by the guideline review comitee. This category indicates a condition where the advantages of using the contraceptive method generally outweigh the theoretical or proven risks',
        mec_category_3:
          'This category is not official in the GRADE approach, it constitutes part of  an alternative classification defined by the guideline development group and approved by the guideline review comitee. This category indicates a condition where the theoretical or proven risks usually outweigh the advantages of using the contraceptive method',
        mec_category_4:
          'This category is not official in the GRADE approach, it constitutes part of  an alternative classification defined by the guideline development group and approved by the guideline review comitee. This category indicates a condition which represents an unacceptable health risk if the contraceptive method is used.'
      },
      type: 'Type',
      coe: 'Certainty of evidence',
      coe_tooltip:
        'The certainy of evidence is a judgement about the extent to which we can be confident that the estimates of effect are correct',
      coe_labels: {
        high: 'high',
        moderate: 'moderate',
        low: 'low',
        'very low': 'very low'
      },
      notes: 'Notes and remarks',
      population: 'Population | Populations',
      intervention: 'Intervention | Interventions',
      sdg3_goals: 'SDG3 target | SDG3 targets',
      sdg3_header_1: 'Browse by',
      sdg3_header_2: 'target:',
      sdg3_targets: {
        '6167009e06768adee26a276f': 'Maternal Health',
        '616700b43552581d99259cbd': 'Infant Health',
        '616700c83d0515512662039e': 'Communicable Diseases',
        '616700dfea88905b6d651ab4': 'Non-Communicable Diseases',
        '6167019d06768adee26a27b6': 'Consumption of Psychoactive Substances',
        '616701b83d051551266203e3': 'Sexual and Reproductive Healthcare',
        '616701f0ea88905b6d651b03': 'Universal Health',
        '616701fd06768adee26a27d3': 'Air, Water and Soil Pollution',
        '6167020b3d05155126620408': 'Health Workforce',
        '6167021bea88905b6d651b12': 'National and Global Health Risks',
        '6167022606768adee26a27e8': 'Road Traffic Accidents',
        '616702393d05155126620415': 'Tobacco Control',
        '61670245ea88905b6d651b27': 'Vaccine and Medicine Development',
        '6232d8b806768a45cafb964b': 'Breastfeeding',
        '5d25e658daeedf1d3af3331b': 'Smoking cessation',
        '623302a1355258bf19d48919': 'Tobacco control policies',
        '623d860d355258962733d076': 'WASH interventions',
        '5d31a969daeedf1d3af3333f': 'Delivery arrangements',
        '61e093773d0515f69ae5f25f': 'Safe abortion services',
        '5dd7a70469c00e7ae9856041': 'Financial arrangements',
        '5dd7eb313c12ef61034d00f3': 'Governance arrangements',
        '5d4c5d0769c00e0d5edaea4b': 'Anesthetic techniques',
        '61969b2aea88903ea3a9cb6b': 'Antenatal care',
        '619d5dc2307372254d2fb70c': 'Family planning',
        '622630fb355258e04d57f2b5': 'Cannabis',
        '62263163ea8890a65d455607': 'Alcohol',
        '622633c5307372a476759d26': 'Opioids',
        '62263906355258e04d57f50e': 'Sedative hypnotics',
        '62263c23ea8890b83445534b': 'Cocaine',
        '62263cdb06768a27badd846a': 'Amphetamines',
        '6226424eea8890cac54552db': 'Hallucinogens',
        '62264853307372b78b75a001': 'Inhalants',
        '6232f74d3552589cf3d489df': 'Occupational or environmental exposure',
        '62af88f506768ad40b76ca75': "Children's exposure to substances",
        '61e02fc03552586c64987855': 'Snakebite',
        '61e0927e06768a05b24df5ea': 'Road traffic injuries',
        '6230e95469c00e8b53a62277': 'Pedestrians',
        '6230ea4d69c00e8b53a622be': 'Motor vehicle drivers',
        '6230f589355258f491a91beb': 'Cyclists',
        '6230f744ea889066d8cf39a5': 'Road crash victims',
        '5e18816a19a11a14c75ed9a1': 'Headaches',
        '5e18816a19a11a14c75ed9a2': 'Migraine',
        '5d7e803069c00e72a5188e4f': 'Miscarriage, stillbirth and abortion',
        '61ae22493d05159df98a787c': 'Abnormal progress of labor',
        '61ae228e06768afaf4086a69': 'Uterine rupture',
        '61ae329e3d0515bfa88a791a': 'Obstetrical bleeding',
        '61ae3b98ea88906f40808bde': 'Prelabor rupture of membranes',
        '61df380b307372c1b76fddfa': 'Long labor',
        '61df3c89307372c1b76fdf53': 'Instrumental delivery',
        '61df3dc73d0515f181e5f312': 'Caesarean section',
        '61df40563552585a4198768a': 'Induction of labor',
        '61df4193307372c1b76fe0b4': 'Perineal trauma',
        '61df4312307372d5566fdd1a': 'Obstetric trauma',
        '61df4fab3552589b2c98759a': 'Hypertensive disorders of pregnancy',
        '61ae37433552583a066132f2': 'Maternal infections',
        '61ae9e133d0515be1f8a7b58': 'Large for gestational age',
        '61df49fb3d05152368e5f105': 'Obstetric thromboembolism',
        '61ae05fd355258b08e6134d0': 'Ectopic pregnancy',
        '60eefcdd3d0515c51d3f02ae': 'Pregnant adolescents',
        '5d062d5fc80dd41e58ba8459': 'Pregnant woman',
        '6269b62a3552584246eb4bb5': 'Diabetes and pregnancy',
        '5cf20ea0c80dd41e58ba8259': 'Viral hepatitis',
        '5d0a8a04c80dd41e57ba89a0': 'Cholera',
        '5d0a8a85dbe4d25bcfbee78f': 'Typhoid and paratyphoid',
        '61e005a2307372bffc6fdf9b': 'Infectious diarrhea',
        '5d0a9c60dbe4d25bcfbee797': 'Tuberculosis',
        '5d0b446adbe4d25bcfbee7fd': 'Sepsis',
        '5d4a7cd869c00e69c91e5ff8': 'Meningitis',
        '5d0b4ec7dbe4d25bcfbee800': 'Dengue',
        '5d52927869c00e3de733d820': 'HIV',
        '5d52d6bf69c00e3de733d835': 'Pneumonia',
        '5d6bcb6369c00e39658ba0a2': 'Malaria',
        '5d7f530269c00e72a5188e5e': 'Chagas disease',
        '5da2f85069c00e78ea7056d5': 'Brucellosis',
        '5da2fbf63c12ef5722a1d717': 'Zika virus disease',
        '61e01ad006768a739d4df424': 'Chikungunya',
        '63c30f7dc96e6d00081e1b35': 'Taenia solium infection',
        '5e7fce7e3d05156b5f5e032a': 'COVID-19',
        '618524ca307372a53541f22e': 'Infectious PH emergencies',
        '628667843552584bba67f67a': 'Monkeypox',
        '61967a183d051589e00a0104': 'Crimean–Congo hemorrhagic fever',
        '619697e869c00e50e228c6c1': 'Plague',
        '61df52ea69c00e31667ed778': 'Anthrax',
        '61df53973552589d7298765a': 'Ascariasis',
        '61e00914ea8890a3bcfe632e': 'Epidemic typhus',
        '61e075323552580bfd9877ea': 'Scrub typhus',
        '61e00ae869c00ecf047ed7a1': 'Gnathostomiasis',
        '61e00c83307372d43a6fdda8': 'Pediculosis capitis',
        '61e0147f3552582e469878a6': 'Histoplasmosis',
        '61e01531307372d43a6fdfe9': 'Japanese encephalitis',
        '61e016663552584ac49875ba': 'Leishmaniasis',
        '61e0174dea8890b596fe633c': 'Measles',
        '61e01812307372e5dc6fdd04': 'Melioidosis',
        '61e0191d3d0515e90ae5f1bf': 'Neglected tropical diseases',
        '61e0773706768ae17b4df63b': 'Strongyloidiasis',
        '61e02d0169c00e0b937ed7e6': 'Scabies',
        '61e073d2ea88903585fe6172': 'Paracoccidioidomycosis',
        '61e076a3ea88903585fe6231': 'Smallpox',
        '61e077fa06768ae17b4df66a': 'Tick-borne encephalitis',
        '61e0788e30737252756fe032': 'Trichomoniasis',
        '5cfa863adbe4d25bcfbee2c7': 'Cancer',
        '62a904023d0515e3d541fcc3': 'Pediatric cancer',
        '5d0b5bdedbe4d25bcfbee803': 'COPD',
        '5d7520f369c00e39658ba0fa': 'Asthma',
        '62a91523ea88903c2895b27b': 'Pediatric respiratory diseases',
        '5d14114adaeedf730426b7b8': 'Connective tissue diseases',
        '5d234900daeedf1d3af33306': 'Dental disorders',
        '5d27332adaeedf1d3bf3330a': 'Hematological conditions',
        '5da2ea543c12ef5722a1d710': 'Sickle cell disease',
        '61e08aee307372777b6fdd98': 'Thalassemia',
        '5d2756d7daeedf1d3af3332a': 'Ischemic heart diseases',
        '5d3f38f669c00e04ee74f5e7': 'Venous thromboembolism',
        '5d3f397e69c00e04ee74f5e9': 'Cerebrovascular diseases',
        '5d3f3aec69c00e04ef74f5c8': 'Hypertension',
        '5d7e2ac769c00e72a5188e41': 'Epilepsy',
        '5d7e2be069c00e72a5188e42': 'Multiple sclerosis',
        '5d99f1f03c12ef11577a0053': 'Parkinson disease',
        '62a90e9eea88903c2895b0d2': 'Pediatric neurological conditions',
        '5d4322c869c00e198a009df3': 'Mental health',
        '5d4322f769c00e198b009e1b': 'Dementia',
        '5d43245e69c00e198b009e1e': 'Substance use disorder',
        '5da097e33c12ef4de3dce666': 'Domestic violence',
        '5da097e33c12ef4de3dce667': 'Sexual abuse',
        '5da097e33c12ef4de3dce668': 'Child abuse',
        '62a90f8e06768aeab576cc5d': 'Pediatric mental health',
        '5d44e01769c00e198b009e39': 'Gastrointestinal disorders',
        '5d52974169c00e3de733d823': 'Liver cirrhosis',
        '5d5832c369c00e490b8f5553': 'Eye disorders',
        '5d5e8e3669c00e507e1c8883': 'Chronic kidney disease',
        '5d9680db69c00e73b4b22508': 'Infertility',
        '5d7e231569c00e72a5188e3c': 'Ear, nose, and throat disorders',
        '5d7f88af69c00e72a3188e15': 'Congenital disorders',
        '5d94f1d13c12ef641517c8d6': 'Endocrine disorders',
        '5d52919a69c00e3de733d81f': 'Diabetes',
        '5d94f2a13c12ef641517c8d7': 'Nutrition and metabolic disorders',
        '5cf00956dbe4d25bcfbedf17': 'Overweight and obesity',
        '62a902c6307372b0385ca5db': 'Pediatric obesity',
        '6231aeb73073724b9bd88b7d': 'Childhood diseases',
        '6231af3d06768abaa9e445b2': 'Newborn health',
        '6232ebdbea8890f8d8bbde6d': 'Preterm/low birth weight',
        '6232f051307372ecd7349e7c': 'Perinatal asphyxia',
        '61df363006768a5c4c4df45a': 'Labour monitoring',
        '60c8b64006768aab069c540d': 'Antimicrobial resistance',
        '60ef101206768ac0e77738d8': 'Sexually transmitted infections',
        '5d0b46cec80dd41e57ba89c1': 'Syphilis',
        '5d7e31f669c00e72a5188e45': 'Trauma, poisoning and injuries',
        '6232f489307372009d349b86': 'Accidental poisoning',
        '61e0866106768af3424df682': 'Sedentary lifestyle',
        '61e08a2569c00ef2ea7ed7d2': 'Salt consumption',
        '6208400dea88909b3f0a3c97': 'Water pollution',
        '620840bb355258cf6dfd0138': 'Air quality',
        '620840e306768aef5a232488': 'Soil pollution',
        '623370453d0515a24b10fd28': 'Health professionals',
        '623382d0307372b09a349e1e': 'Health associate professionals',
        '6233909a69c00ed278da2098': 'Personal care workers in health services',
        '6233924406768a3c75fb97a3': 'Health management and support personnel',
        '619a453469c00e98db28c887': 'Weather-related disasters',
        '623450bb307372f556349d92': 'Man-made disasters',
        '62345349ea8890143ebbdcab': 'Earth movement disasters',
        '630fdb8dc7585e000861eb74': 'Pain during labor',
        '630fd98ac7585e000861eaec': 'Asymptomatic bacteriuria in pregnancy',
        '5d6848cb69c00e131b5a4cc0': 'Labor and delivery',
        '630fd43db25ac300085beafb': 'Postpartum period',
        '630fd787b25ac300085beba6': 'Maternal malnutrition',
        '630fddefb128010008f0ed9f': 'Obstetric procedures',
        other_populations: 'Other populations'
      },
      sdg3_populations: {
        '5cec39fcdbe4d24b26f43daf': 'Skin disorders',
        '5cf20e7bc80dd41e57ba82b8': 'Infectious diseases',
        '5cfa863adbe4d25bcfbee2c7': 'Cancer',
        '5d0b5a7bdbe4d25bcebee2a3': 'Respiratory diseases',
        '5d14114adaeedf730426b7b8': 'Connective tissue diseases',
        '5d234900daeedf1d3af33306': 'Dental disorders',
        '5d27332adaeedf1d3bf3330a': 'Hematological conditions',
        '5d27427edaeedf1d3af33328': 'Cardiovascular diseases',
        '5d3f3da269c00e04ee74f5ea': 'Neurological conditions',
        '5d4322c869c00e198a009df3': 'Mental health',
        '5d44e01769c00e198b009e39': 'Gastrointestinal disorders',
        '5d5832c369c00e490b8f5553': 'Eye disorders',
        '5d5e8d9d69c00e507e1c8882': 'Genitourinary disorders',
        '5d7e231569c00e72a5188e3c': 'Ear, nose, and throat disorders',
        '5d7f88af69c00e72a3188e15': 'Congenital disorders',
        '5d94f1d13c12ef641517c8d6': 'Endocrine disorders',
        '5d94f2a13c12ef641517c8d7': 'Nutrition and metabolic disorders',
        '5d7d93b769c00e72a5188e34': "Obstetrics, gynecology and women's health",
        '61ae329e3d0515bfa88a791a': 'Obstetrical bleeding'
      },
      sdg3_interventions: {
        '5cec5dfdc80dd40a6f61aea0': 'Pharmacological interventions',
        '5d1b60b4daeedf74ca4f4b51': 'Physical activity and physical therapy',
        '5d247420daeedf1d3af3330e': 'Complementary and alternative medicine',
        '5d25202adaeedf1d3af33311': 'Procedures',
        '5d2523a9daeedf1d3af33312': 'Diet and dietary interventions',
        '5d252f01daeedf1d3af33314': 'Behavioral interventions',
        '5d25e34fdaeedf1d3af33315': 'Public health',
        '5d25e37cdaeedf1d3af33316': 'Health systems',
        other_interventions: 'Other interventions'
      },
      history: 'History',
      filters: {
        show_filters: 'Show filters',
        hide_filters: 'Hide filters',
        active_filters: '({active} active)',
        name: 'Filters',
        year: {
          name: 'Year',
          placeholder: 'All',
          options: {
            last_year: lastYear(),
            last_two: `Since ${lastTwoYears()}`,
            last_five: `Since ${lastfiveYears()}`
          }
        },
        age: {
          name: 'Age',
          placeholder: 'All',
          options: {
            children: 'Children',
            adolescents: 'Adolescents',
            adults: 'Adults'
          }
        },
        institution: {
          name: 'Institution',
          placeholder: 'All',
          options: {
            who: 'World Health Organization',
            paho: 'Pan American Health Organization'
          }
        }
      },
      loading: 'loading',
      see_episte: 'See in Epistemonikos',
      see_love: 'See in L·OVE',
      see_map: 'See map',
      map_button:
        'Select one of the 13 SDG-3 targets to see the recommendations map',
      toggle_guidelines: 'Show guideline title',
      view_guideline: 'View guideline',
      pending_translation: 'Translation pending',
      total_recs: 'Total: {total} recommendations',
      platform_title: 'PAHO/WHO GRADE recommendations for the SDG-3',
      show_more: 'More details',
      fulltext: 'Fulltext',
      search_by_keyword: 'Search in BIGG-REC'
    },
    pico_builder: {
      no_hits: 'No results found',
      sdg3_list_title: 'Select SDG3 target',
      treatment_list_title: 'Select intervention',
      population_list_title: 'Select population',
      show_more: 'Show more',
      search: 'Search keyword'
    },
    recommendation: {
      history_message: 'History of recommendation',
      superseded: 'Superseded',
      superseded_tooltip:
        'This recommendation has been superseded and is no longer valid',
      thread_message:
        'This is a valid recommendation also available in the following guidelines:',
      newer_versions:
        'This recommendation has been superseded by the following version and is no longer valid',
      older_versions: 'This recommendation supersedes the following',
      appears_in: 'This recommendation appears in the following guidelines'
    },
    guideline: {
      focus: 'Focus',
      focus_labels: {
        public_health: 'Public health',
        clinical_practice: 'Clinical practice',
        health_policy_systems: 'Health policy and systems'
      },
      users: 'Users',
      users_labels: {
        healthcare_workers: 'Healthcare workers',
        public_health_authorities: 'Public health authorities',
        health_facility_administrators: 'Health facility administrators',
        occupational_health_authorities: 'Occupational healthauthorities'
      },
      organization_type: 'Organization type',
      organization_types: {
        intergov_not_profit: 'Intergovernmental-not for profit',
        gov_not_profit: 'Governmental-Not for profit',
        for_profit: 'For profit'
      },
      recs_included: 'Recommendations included in this guideline:',
      ext_links: 'External links:',
      institution: 'Institution',
      links: 'Links'
    },
    top_menu: {
      home: 'Home',
      terms:
        "BIREME/PAHO's terms and conditions for the use of its websites and systems",
      about: 'About',
      disclaimer: 'Disclaimer',
      disclaimer_content:
        'We do not take responsibility for the accuracy or completeness of the content contained within this website. Although reasonable efforts and care have been applied in its preparation, we assume no liability or responsibility for errors or omissions and consulting the original source of information may be required. Recommendations may be updated or changed as the research evidence and guideline literature accumulate. The information provided within this website is not intended as a substitute for professional advice.',
      glossary: 'Glossary',
      glossary_tables: [
        {
          title: 'Interpretation of Strong and Conditional Recommendations',
          fields:[
            {key:"Implications_for",label:"Implications For"},
            {key:"Strong_recommendation",label:"Strong Recommendation"},
            {key:"Conditional_recommendation",label:"Conditional Recommendation"},
          ],
          content: [
            {
              Implications_for: 'Patients',
              Strong_recommendation:
                'Most individuals in this situation would want the recommended course of action, and only a small proportion would not.',
              Conditional_recommendation:
                'The majority of individuals in this situation would want the suggested course of action, but many would not. Decision aids may be useful in helping patients to make decisions consistent with their individuals risks, values and preferences.'
            },
            {
              Implications_for: 'Clinicians',
              Strong_recommendation:
                'Most individuals in this situation would want the recommended course of action, and only a small proportion would not.',
              Conditional_recommendation:
                'Recognize that different choices will be appropriate for individual patients and that you must help each patient arrive at a management decision consistent with his or her values and preferences. Decision aids may be useful in helping individuals to make decisions consistent with their individual risks, values and preferences.'
            },
            {
              Implications_for: 'Policy makers',
              Strong_recommendation:
                'The recommendation can be adopted as policy in most situations. Adherence to this recommendation according to the guideline could be used as a quality criterion or performance indicator.',
              Conditional_recommendation:
                'Policymaking will require substantial debate and involvement of various stakeholders. Performance measures should assess if decision-making is appropriate.'
            },
            {
              Implications_for: 'Researchers',
              Strong_recommendation:
                'The recommendation is supported by credible research or other convincing judgments that make additional research unlikely to alter the recommendation. On occasion, a strong recommendation is based on low or very low certainty of the evidence. In such instances, further research may provide important information that alters the recommendations.',
              Conditional_recommendation:
                'The recommendation is likely to be strengthened (for future updates or adaptation) by additional research. An evaluation of the conditions and criteria (and the related judgments, research evidence, and additional considerations) that determined the conditional (rather than strong) recommendation will help identify possible research gaps.'
            }
          ]
        }
      ],
      glossary_content: {
        point_1: {
          title: 'Recommendation',
          content:
            'Actionable statement about the choice between two or more intervention options in a specific population, and in a specific setting when relevant. Alternative option(s) [comparator(s)] should be specified when not self-evident. The statement should have a clear direction (for or against an option) and strength (e.g., strong or conditional). Ideally, the strength of recommendation and the certainty of supporting evidence are explicitly stated. A recommendation should be supported by a deliberative and structured development process. In particular it should be supported by systematic review or health technology assessment for the factors that determine its direction and strength.',
          id: '1'
        },
        point_2: {
          title: 'Research Recommendations',
          content:
            'Actionable statement about the use of intervention options in a specific population, only in the setting of research participation. Research recommendations are appropriate when three conditions are met: i) the certainty of the available evidence does not allow guideline group to issue a formal recommendation; ii) further research has a large potential for reducing uncertainty about the desirable or undesirable consequences of the intervention; iii) and further research is deemed good value for the anticipated costs. The research recommendations should be detailed regarding the specific research question(s) that investigators should address. The recommendation for research may be accompanied by an explicit [strong] recommendation not to use the intervention outside of the research context.',
          id: '2'
        },
        point_9: {
          title: 'GRADE',
          content:
            'The GRADE (Grading of Recommendations Assessment, Development and Evaluation) approach is a system for rating the quality of a body of evidence in systematic reviews and other evidence syntheses, such as health technology assessments, and guidelines and grading recommendations in health care. GRADE offers a transparent and structured process for developing and presenting evidence summaries and for carrying out the steps involved in developing recommendations. It can be used to develop clinical practice guidelines (CPG) and other health care recommendations (e.g. in public health, health policy and systems and coverage decisions).',
          id: '9'
        },
        point_3: {
          title: 'Official GRADE categories of recommendations/classification',
          text: {
            0: {
              id: '0',
              content:
                'If the panel is highly confident of the balance between desirable and undesirable consequences, they make a strong recommendation for (desirable outweighs undesirable) or against (undesirable outweighs desirable) an intervention.',
              title: 'Strong recommendation'
            },
            1: {
              id: '1',
              has_table: 'VERDADERO',
              title: 'Conditional recommendation',
              content:
                'If the guideline development group or panel is not very confident of the balance between desirable and undesirable consequences for the intended use of the recommendation, they offer a conditional (also called weak) recommendation. This may occur when the certainty of evidence is very low or low, if values and preferences, cost, equity, acceptability, feasibility and other factors may depend on the setting where the intervention or option is implemented or are variable or uncertain.'
            },
            2: {
              id: '2',
              content:
                'Actionable statement about the use of intervention options in a specific population, only in the setting of research participation',
              title: 'Research Recommendations'
            },

          },
          id: '3'
        },
        point_4: {
          title: 'Non-official GRADE type of recommendations',
          intro:
            'The following categories are not official in the GRADE approach, they constitute part of alternative classifications defined by some WHO guideline development groups and approved by WHO guideline review comitee.',
          text: {
            0: {
              id: '0',
              title: 'Recommended',
              content:
                'This category indicates that the intervention or option should be implemented.'
            },
            1: {
              id: '1',
              title: 'Not recommended or recommend against the practice',
              content:
                'This category indicates that the intervention or option should not be implemented.'
            },
            2: {
              id: '2',
              title: 'Recommended only in specific contexts',
              content:
                'This category indicates that the intervention or option is applicable only to the condition, setting or population specified in the recommendation, and should only be implemented in these contexts.'
            },
            3: {
              id: '3',
              title: 'Recommend with targeted monitoring and evaluation',
              content:
                'This rating indicates uncertainty about the effectiveness or acceptability of an intervention, especially with regard to particular contexts or conditions. Interventions classified as such can be considered for implementation (including at scale), provided they are accompanied by targeted monitoring and evaluation. Particular attention must be given to specific issues about which there are concerns (such as risks or harms) and for which little or no relevant information is available. Information about monitoring and evaluation may be obtained from a range of sources, including routine data and survey data. The Guidance Panel attempted to specify which aspects of the interventions required monitoring and specified the relevant indicators'
            },
            4: {
              id: '4',
              title: 'Recommended only in the context of rigorous research',
              content:
                'This category indicates that there are important uncertainties about the intervention or option. In such instances, implementation can still be undertaken on a large scale, provided that it takes the form of research that is able to address unanswered questions and uncertainties related both to effectiveness of the intervention or option, and its acceptability and feasibility'
            },
            5: {
              id: '5',
              title: 'MEC category 1',
              content:
                'This category is not official in the GRADE approach, it constitutes part of an alternative classification defined by the guideline development group and approved by the guideline review comitee. This category indicates a condition for which there is no restriction for the use of the contraceptive method'
            },
            6: {
              id: '6',
              title: 'MEC category 2',
              content:
                'This category is not official in the GRADE approach, it constitutes part of an alternative classification defined by the guideline development group and approved by the guideline review comitee. This category indicates a condition where the advantages of using the contraceptive method generally outweigh the theoretical or proven risks'
            },
            7: {
              id: '7',
              title: 'MEC category 3',
              content:
                'This category is not official in the GRADE approach, it constitutes part of an alternative classification defined by the guideline development group and approved by the guideline review comitee. This category indicates a condition where the theoretical or proven risks usually outweigh the advantages of using the contraceptive method'
            },
            8: {
              id: '8',
              title: 'MEC category 4',
              content:
                'This category is not official in the GRADE approach, it constitutes part of an alternative classification defined by the guideline development group and approved by the guideline review comitee. This category indicates a condition which represents an unacceptable health risk if the contraceptive method is used.'
            },

          },
          id: '4'
        },
        point_5: {
          title: 'Certainty of evidence',
          intro:
            'Certainty that an estimate of association or effect is correct or, better, that a true effect lies on one side of a specified threshold or within a chosen range. The certainty of evidence, also called confidence in estimates of effects, quality of evidence, strengths of evidence or levels of evidence is expressed in four categories high, moderate, low and very low depending on the evaluation of the following signalling questions for a body of evidence (in quotation marks the specific domains addressed by scientists):',
          text: {
            0: {
              id: '0',
              content:
                'Are the research studies well done? “Limitations in the detailed study design and execution or also called risk of bias or internal validity”'
            },
            1: {
              id: '1',
              content:
                'Are the results consistent across studies? “Inconsistency”'
            },
            2: {
              id: '2',
              content:
                'How directly do the results relate to our question? “Indirectness, applicability, generalizability, external validity”'
            },
            3: {
              id: '3',
              content:
                'Is the effect size precise - due to random error? “Imprecision”'
            },
            4: {
              id: '4',
              content:
                'Are these all of the studies that have been conducted? “Publication Bias”'
            },
            5: {
              id: '5',
              content:
                'Is there anything else that makes us particularly certain? “Large effects, worst case scenario predictors still strong conclusions, exposure-effect relation”'
            },

          },
          id: '5'
        },
        point_6: {
          title: 'Certainty in the estimate of effect',
          content:
            'This expresses the certainty in the effect of an intervention considering the body of evidence and is akin to certainty of evidence.',
          id: '6'
        },
        point_7: {
          title: 'Recommendation map',
          content:
            'A tool to organize recommendations from a series of guidelines on a certain condition, report features around the evidence and judgments that inform the recommendation, and highlight clusters and gaps in availability of recommendations in a digital, visual schematic. Recommendation maps are organized by the components of the PICO (Population, Interventions, Comparator, Outcome) question that guided the development of the respective recommendation',
          id: '7'
        },
        point_8: {
          title: 'GRADE handbook glossary',
          content:
            'https://gdt.gradepro.org/app/handbook/handbook.html#h.buaodtl66dyx',
          id: '8'
        }
      },
      doc_title: 'BIGG-REC - Recomendaciones GRADE'
    },
    map: {
      recommendations_map: 'Recommendations map',
      back_button: 'Go back'
    },
    footer: {
      powered: 'Powered by',
      privacy_policy: {

        content_1: 'example {link}',
        link: 'url@gmail',
        main_title: 'Privacy Policy'
      },
      contact: {
        name: 'Contact',
        text:"For questions or inquiries please contact us at",
        content: {
          title_1: 'Placeholder'
        }
      },
      terms_and_conditions: {
        text: [
          {
            id: '0',
            content:
              'Content of this site or linked by Epistemonikos Database ("Epistemonikos") to other sites is provided in a free and open way, without contractual link created or existing between the owners and/or administrators of this website and the project\'s participants, and users. Content owned by Epistemonikos that is made available to users can be freely used, subject to the terms and conditions of its licensing scheme. Content from third parties linked to, can be used in accordance with the licensing requirements of the rights owner of the linked content.'
          },
          {
            id: '1',
            content:
              'Only the English version of all the titles and abstracts of available documents, as well as translations marked with a star can be considered reliable. However, regarding all documents and translations, Epistemonikos, its collaborators, administrators, programmers, sponsors, researchers and in general anyone related to Epistemonikos or that works in the project, do not act as guarantors nor are in any way obligated or liable regarding the exactitude, completeness, or use of the provided information, which is owned by their corresponding rights holders.'
          },
          {
            id: '2',
            content:
              'Epistemonikos does not support, endorse or recommend any commercial product, treatment or service. The points of view and opinions of individual authors of the information that is made available do not necessarily reflect the opinion of Epistemonikos, nor of its collaborators, administrators, programmers, sponsors, researchers, and in general of any person related to Epistemonikos or that works in the project. Therefore, they cannot be used for commercial purposes or in order to support a specific product, treatment or service.'
          },
          {
            id: '3',
            content:
              'Epistemonikos does not provide specific health advice, consulting or recommendations but instead seeks to provide to the general public, in a systematized form, the best and most reliable available information so as to better understand the problems of healthcare. Epistemonikos does not assume any legal obligation or liability derived from the application of the information to real situations. The information made available to the public by Epistemonikos, especially about pharmacotherapy and surgical procedures, does not in any way substitute, replace or complement advice provided by doctors or other healthcare professionals. Any use of the information contained in Epistemonikos is the sole responsibility of the user.'
          },
          {
            id: '4',
            content:
              'Epistemonikos.org is a collaborative nonprofit project maintained by Epistemonikos Foundation. All of its content and features are available for free.'
          }
        ],
        intellectual_property: {
          text: [
            {
              id: '0',
              content:
                'Epistemonikos links to contents located in other websites and databases, mainly serialized scientific journals. Said links provide access to content owned by third parties, when allowed by them. Content located in linked sites are in no way amended or modified. Copyright of the content to which Epistemonikos links is owned by their respective rights holders.'
            },
            {
              id: '1',
              content:
                'For better reference, and for purposes of research and academia, Epistemonikos can provide fragments of the linked content, including a direct reference to the source, title and author.'
            },
            {
              id: '2',
              content:
                'Any trademark, whether for products, services, composite marks, collective marks, industrial designs, patents or other intellectual property rights than can be addressed, commented on or cited in Epistemonikos’ content, or content linked to, are owned by their respective rights holders. Unless expressly stated, Epistemonikos has no relation nor contractual link to the corresponding rights holders.'
            }
          ],
          laws: [
            {
              text: [
                {
                  id: '0',
                  content:
                    'Epistemonikos is a collaborative nonprofit project that strives to publicize and ease access to evidence-based medicine knowledge. Linked information is used in compliance with the laws and international treaties in force in the Republic of Chile.'
                },
                {
                  id: '1',
                  content:
                    'If you own the copyright of a work contained or referred to in the website, and believe the Epistemonikos’ use of it is infringing, please contact contact@epistemonikos.org, stating your rights to the work, its location in the website, the rights allegedly infringed and, if applicable, the user that provides the infringing content. If justified, in compliance with Law 17.336 of Intellectual Property, the infringing content will be removed.'
                }
              ],
              name: 'Law 17.336 of Intellectual Property (Chile)'
            }
          ],
          title: 'Intellectual Property'
        },
        jurisdiction: {
          text: [
            {
              id: '0',
              content:
                'Epistemonikos publishes, links to or makes available to users, all its content in compliance with the laws of the Republic of Chile. Any controversy, conflict or claim arising from the website and its content, will be decided in a Chilean court of law, in compliance with applicable laws in force in the Republic of Chile.'
            }
          ],
          title: 'Jurisdiction'
        },
        main_title: 'Terms and Conditions'
      }
    },
    modals: {
      contact: {
        title: 'Contact',
        content:
          'For questions or inquiries related to this privacy policy, please contact us at bir.online@paho.org'
      }
    },
    undefined: 'en'
  },
  es: {
    general: {
      doc_title: 'BIGG-REC - Recomendaciones GRADE',
      home: 'Inicio',
      map: 'Mapa',
      year: 'Año',
      clear: 'Limpiar',
      institutions: {
        WHO: 'Organización Mundial de la Salud',
        PAHO: 'Organización Panamericana de la Salud'
      },
      recommendation: 'Recomendación | Recomendaciones',
      guideline: 'Directriz | Directrices',
      direction: 'Dirección',
      rec_strength: 'Fuerza de la recomendación',
      rec_type_labels: {
        strong: 'Fuerte',
        conditional: 'Condicional',
        recommended: 'Recomendado',
        not_recommended: 'No recomendado',
        research_context:
          'Recomendado en el contexto de investigaciones rigurosas',
        context_specific: 'Recomendado en contextos específicos',
        targeted_monitoring_context: 'Recomendado en contexto de supervisión',
        mec_1: 'MEC 1',
        mec_2: 'MEC 2',
        mec_3: 'MEC 3',
        mec_4: 'MEC 4'
      },
      rec_type_tooltips: {
        strong:
          'Una recomendación puede ser fuerte o condicional. Cuando una recomendación es fuerte, la mayoría de los individuos desearían el curso de acción sugerido. Cuando una recomendación es condicional, la mayoría de las personas quieren seguirla, pero es posible que primero necesiten hablar más con su profesional de la salud',
        conditional:
          'Una recomendación puede ser fuerte o condicional. Cuando una recomendación es fuerte, la mayoría de los individuos desearían el curso de acción sugerido. Cuando una recomendación es condicional, la mayoría de las personas quieren seguirla, pero es posible que primero necesiten hablar más con su profesional de la salud',
        recommended:
          'Esta categoría indica que la intervención u opción debe implementarse. Esta categoría no es oficial en el enfoque GRADE, constituye parte de una clasificación alternativa definida por el grupo de desarrollo de la guía y aprobada por el comité de revisión de la guía',
        not_recommended:
          'Esta categoría indica que la intervención u opción no debe implementarse. Esta categoría no es oficial en el enfoque GRADE, constituye parte de una clasificación alternativa definida por el grupo de desarrollo de la guía y aprobada por el comité de revisión de la guía',
        context_specific_recommendation:
          'Esta categoría indica que la intervención u opción es aplicable solo a la condición, entorno o población especificada en la recomendación, y solo debe implementarse en estos contextos. Esta categoría no es oficial en el enfoque GRADE, constituye parte de una clasificación alternativa definida por el grupo de desarrollo de la guía y aprobada por el comité de revisión de la guía',
        research_context_recommendation:
          'Esta categoría indica que existe importante incertidumbre sobre la intervención u opción. En tales casos, la implementación aún puede emprenderse a gran escala, siempre que adopte la forma de investigación que sea capaz de abordar preguntas sin respuesta e incertidumbres relacionadas tanto con la efectividad de la intervención u opción como con su aceptabilidad y viabilidad. Esta categoría no es oficial en el enfoque GRADE, constituye parte de una clasificación alternativa definida por el grupo de desarrollo de la guía y aprobada por el comité de revisión de la guía',
        recommended_in_the_context_of_targeted_monitoring_and_evaluation:
          'Esta calificación indica incertidumbre sobre la efectividad o aceptabilidad de una intervención, especialmente con respecto a contextos o condiciones particulares. Esta categoría no es oficial en el enfoque GRADE, constituye parte de una clasificación alternativa definida por el grupo de desarrollo de la guía y aprobada por el comité de revisión de la guía',
        mec_category_1:
          'Esta categoría no es oficial en el enfoque GRADE, constituye parte de una clasificación alternativa definida por el grupo de desarrollo de la guía y aprobada por el comité de revisión de la guía. Esta categoría indica una condición para la cual no hay restricción para el uso del método anticonceptivo.',
        mec_category_2:
          'Esta categoría no es oficial en el enfoque GRADE, constituye parte de una clasificación alternativa definida por el grupo de desarrollo de la guía y aprobada por el comité de revisión de la guía. Esta categoría indica una condición donde las ventajas de usar el método anticonceptivo generalmente superan los riesgos teóricos o probados',
        mec_category_3:
          'Esta categoría no es oficial en el enfoque GRADE, constituye parte de una clasificación alternativa definida por el grupo de desarrollo de la guía y aprobada por el comité de revisión de la guía. Esta categoría indica una condición en la que los riesgos teóricos o probados suelen superan las ventajas de utilizar el método anticonceptivo.',
        mec_category_4:
          'Esta categoría no es oficial en el enfoque GRADE, constituye parte de una clasificación alternativa definida por el grupo de desarrollo de la guía y aprobada por el comité de revisión de la guía. Esta categoría indica una condición que representa un problema de salud inaceptable. riesgo si se utiliza el método anticonceptivo.'
      },
      type: 'Tipo',
      coe: 'Certeza de la evidencia',
      coe_tooltip:
        'La certeza de la evidencia es el juicio sobre la medida en que podemos estar seguros de que las estimaciones de efecto son correctas',
      coe_labels: {
        high: 'alta',
        moderate: 'moderada',
        low: 'baja',
        'very low': 'muy baja'
      },
      notes: 'Anotaciones',
      population: 'Población | Poblaciones',
      intervention: 'Intervención | Intervenciones',
      sdg3_goals: 'Meta ODS3 | Metas ODS3',
      sdg3_header_1: 'Navegar por meta',
      sdg3_header_2: 'Objetivo:',
      sdg3_targets: {
        '6167009e06768adee26a276f': 'Salud materna',
        '616700b43552581d99259cbd': 'Salud infantil',
        '616700c83d0515512662039e': 'Enfermedades transmisibles',
        '616700dfea88905b6d651ab4': 'Enfermedades no transmisibles',
        '6167019d06768adee26a27b6': 'Consumo de sustancias psicoactivas',
        '616701b83d051551266203e3': 'Salud sexual y reproductiva',
        '616701f0ea88905b6d651b03': 'Cobertura universal de Salud',
        '616701fd06768adee26a27d3': 'Contaminación del aire, agua y suelo',
        '6167020b3d05155126620408': 'Personal de salud',
        '6167021bea88905b6d651b12': 'Riesgos para la salud nacional y global',
        '6167022606768adee26a27e8': 'Accidentes de tránsito',
        '616702393d05155126620415': 'Control del tabaco',
        '61670245ea88905b6d651b27': 'Desarrollo de vacunas y medicamentos',
        '6232d8b806768a45cafb964b': 'Lactancia materna',
        '5d25e658daeedf1d3af3331b': 'Dejar de fumar',
        '623302a1355258bf19d48919': 'Políticas de control del tabaco',
        '623d860d355258962733d076': 'Intervenciones WASH',
        '5d31a969daeedf1d3af3333f': 'Disposiciones de entrega',
        '61e093773d0515f69ae5f25f': 'Servicios de aborto seguro',
        '5dd7a70469c00e7ae9856041': 'Acuerdos financieros',
        '5dd7eb313c12ef61034d00f3': 'Disposiciones de gobierno',
        '5d4c5d0769c00e0d5edaea4b': 'Técnicas anestésicas',
        '61969b2aea88903ea3a9cb6b': 'Cuidado antenatal',
        '619d5dc2307372254d2fb70c': 'Planificación familiar',
        '622630fb355258e04d57f2b5': 'Canabis',
        '62263163ea8890a65d455607': 'Alcohol',
        '622633c5307372a476759d26': 'Opioides',
        '62263906355258e04d57f50e': 'Hipnóticos sedantes',
        '62263c23ea8890b83445534b': 'Cocaína',
        '62263cdb06768a27badd846a': 'Anfetaminas',
        '6226424eea8890cac54552db': 'Alucinógenos',
        '62264853307372b78b75a001': 'Inhalantes',
        '6232f74d3552589cf3d489df': 'Exposición ocupacional o ambiental',
        '62af88f506768ad40b76ca75': 'Exposición a sustancias en niños',
        '61e02fc03552586c64987855': 'Mordedura de serpiente',
        '61e0927e06768a05b24df5ea': 'Lesiones de tránsito',
        '6230e95469c00e8b53a62277': 'Peatones',
        '6230ea4d69c00e8b53a622be': 'Conductores de vehículos motorizados',
        '6230f589355258f491a91beb': 'Ciclistas',
        '6230f744ea889066d8cf39a5': 'Víctimas de accidentes de tráfico',
        '5e18816a19a11a14c75ed9a1': 'Dolores de cabeza',
        '5e18816a19a11a14c75ed9a2': 'Migraña',
        '5d7e803069c00e72a5188e4f': 'Aborto espontáneo, muerte fetal y aborto',
        '61ae22493d05159df98a787c': 'Progreso anormal del trabajo de parto',
        '61ae228e06768afaf4086a69': 'Rotura uterina',
        '61ae329e3d0515bfa88a791a': 'Sangrado obstétrico',
        '61ae3b98ea88906f40808bde':
          'Rotura de membranas antes del trabajo de parto',
        '61df380b307372c1b76fddfa': 'Parto prolongado',
        '61df3c89307372c1b76fdf53': 'Parto instrumentado',
        '61df3dc73d0515f181e5f312': 'Cesáreas',
        '61df40563552585a4198768a': 'Inducción del parto',
        '61df4193307372c1b76fe0b4': 'Traumatismo perineal',
        '61df4312307372d5566fdd1a': 'Trauma obstétrico',
        '61df4fab3552589b2c98759a': 'Trastornos hipertensivos del embarazo',
        '61ae37433552583a066132f2': 'Infecciones maternas',
        '61ae9e133d0515be1f8a7b58': 'Grande para la edad gestacional',
        '61df49fb3d05152368e5f105': 'Tromboembolismo obstétrico',
        '61ae05fd355258b08e6134d0': 'Embarazo ectópico',
        '60eefcdd3d0515c51d3f02ae': 'Adolescentes embarazadas',
        '5d062d5fc80dd41e58ba8459': 'Mujeres embarazadas',
        '6269b62a3552584246eb4bb5': 'Diabetes y embarazo',
        '5cf20ea0c80dd41e58ba8259': 'Hepatitis viral',
        '5d0a8a04c80dd41e57ba89a0': 'Cólera',
        '5d0a8a85dbe4d25bcfbee78f': 'Tifoidea y paratifoidea',
        '61e005a2307372bffc6fdf9b': 'Diarrea infecciosa',
        '5d0a9c60dbe4d25bcfbee797': 'Tuberculosis',
        '5d0b446adbe4d25bcfbee7fd': 'Septicemia',
        '5d4a7cd869c00e69c91e5ff8': 'Meningitis aguda',
        '5d0b4ec7dbe4d25bcfbee800': 'Dengue',
        '5d52927869c00e3de733d820': 'VIH',
        '5d52d6bf69c00e3de733d835': 'Neumonía',
        '5d6bcb6369c00e39658ba0a2': 'Malaria',
        '5d7f530269c00e72a5188e5e': 'Enfermedad de Chagas',
        '5da2f85069c00e78ea7056d5': 'Brucelosis',
        '5da2fbf63c12ef5722a1d717': 'Enfermedad del virus Zika',
        '61e01ad006768a739d4df424': 'Chikungunya',
        '63c30f7dc96e6d00081e1b35': 'Infección de Taenia solium',
        '5e7fce7e3d05156b5f5e032a': 'COVID-19',
        '618524ca307372a53541f22e': 'Emergencias  infecciosas de Sallud Pública',
        '628667843552584bba67f67a': 'Viruela símica',
        '61967a183d051589e00a0104': 'Fiebre hemorrágica de Crimea-Congo',
        '619697e869c00e50e228c6c1': 'Plaga',
        '61df52ea69c00e31667ed778': 'Ántrax',
        '61df53973552589d7298765a': 'Ascaridiasis',
        '61e00914ea8890a3bcfe632e': 'Tifus epidémico',
        '61e075323552580bfd9877ea': 'Tifus por ácaros',
        '61e00ae869c00ecf047ed7a1': 'Gnatostomiasis',
        '61e00c83307372d43a6fdda8': 'Pediculosis capitis',
        '61e0147f3552582e469878a6': 'Histoplasmosis',
        '61e01531307372d43a6fdfe9': 'La encefalitis japonesa',
        '61e016663552584ac49875ba': 'Leishmaniasis',
        '61e0174dea8890b596fe633c': 'Sarampión',
        '61e01812307372e5dc6fdd04': 'Melioidosis',
        '61e0191d3d0515e90ae5f1bf': 'Enfermedades tropicales desatendidas',
        '61e0773706768ae17b4df63b': 'Estrongiloidiasis',
        '61e02d0169c00e0b937ed7e6': 'Sarna',
        '61e073d2ea88903585fe6172': 'Paracoccidioidomicosis',
        '61e076a3ea88903585fe6231': 'Viruela',
        '61e077fa06768ae17b4df66a': 'Encefalitis transmitida por garrapatas',
        '61e0788e30737252756fe032': 'Tricomoniasis',
        '5cfa863adbe4d25bcfbee2c7': 'Cáncer',
        '62a904023d0515e3d541fcc3': 'Cáncer infantil',
        '5d0b5bdedbe4d25bcfbee803': 'EPOC',
        '5d7520f369c00e39658ba0fa': 'Asma',
        '62a91523ea88903c2895b27b': 'Enfermedades respiratorias en la infancia',
        '5d14114adaeedf730426b7b8': 'Enfermedades del tejido conectivo',
        '5d234900daeedf1d3af33306': 'Trastornos dentales',
        '5d27332adaeedf1d3bf3330a': 'Condiciones hematológicas',
        '5da2ea543c12ef5722a1d710': 'Enfermedad de células falciformes',
        '61e08aee307372777b6fdd98': 'Talasemia',
        '5d2756d7daeedf1d3af3332a': 'Cardiopatías isquémicas',
        '5d3f38f669c00e04ee74f5e7': 'Tromboembolismo venoso',
        '5d3f397e69c00e04ee74f5e9': 'Enfermedades cerebrovasculares',
        '5d3f3aec69c00e04ef74f5c8': 'Hipertensión',
        '5d7e2ac769c00e72a5188e41': 'Epilepsia',
        '5d7e2be069c00e72a5188e42': 'Esclerosis múltiple',
        '5d99f1f03c12ef11577a0053': 'Enfermedad de Parkinson',
        '62a90e9eea88903c2895b0d2': 'Enfermedades neurológicas en la infancia',
        '5d4322c869c00e198a009df3': 'Salud mental',
        '5d4322f769c00e198b009e1b': 'Demencia',
        '5d43245e69c00e198b009e1e': 'Trastorno por uso de sustancias',
        '5da097e33c12ef4de3dce666': 'Violencia doméstica',
        '5da097e33c12ef4de3dce667': 'Abuso sexual',
        '5da097e33c12ef4de3dce668': 'Maltrato infantil',
        '62a90f8e06768aeab576cc5d': 'Salud mental infantil',
        '5d44e01769c00e198b009e39': 'Desórdenes gastrointestinales',
        '5d52974169c00e3de733d823': 'Cirrosis hepática',
        '5d5832c369c00e490b8f5553': 'Trastornos oculares',
        '5d5e8e3669c00e507e1c8883': 'Enfermedad renal cronica',
        '5d9680db69c00e73b4b22508': 'Infertilidad',
        '5d7e231569c00e72a5188e3c':
          'Trastornos del oído, la nariz y la garganta',
        '5d7f88af69c00e72a3188e15': 'Trastornos congénitos',
        '5d94f1d13c12ef641517c8d6': 'Desordenes endocrinos',
        '5d52919a69c00e3de733d81f': 'Diabetes',
        '5d94f2a13c12ef641517c8d7': 'Nutrición y trastornos metabólicos',
        '5cf00956dbe4d25bcfbedf17': 'Sobrepeso y obesidad',
        '62a902c6307372b0385ca5db': 'Obesidad infantil',
        '6231aeb73073724b9bd88b7d': 'Enfermedades infantiles',
        '6231af3d06768abaa9e445b2': 'Salud del recién nacido',
        '6232ebdbea8890f8d8bbde6d': 'Prematuro/bajo peso al nacer',
        '6232f051307372ecd7349e7c': 'Asfixia perinatal',
        '61df363006768a5c4c4df45a': 'Monitoreo del parto',
        '60c8b64006768aab069c540d': 'Resistencia antimicrobiana',
        '60ef101206768ac0e77738d8': 'Infecciones de transmisión sexual',
        '5d0b46cec80dd41e57ba89c1': 'Sífilis',
        '5d7e31f669c00e72a5188e45': 'Traumatismos, intoxicaciones y lesiones',
        '6232f489307372009d349b86': 'Envenenamiento accidental',
        '61e0866106768af3424df682': 'Estilo de vida sedentario',
        '61e08a2569c00ef2ea7ed7d2': 'Consumo de sal',
        '6208400dea88909b3f0a3c97': 'Contaminación del agua',
        '620840bb355258cf6dfd0138': 'Calidad del aire',
        '620840e306768aef5a232488': 'Contaminación del suelo',
        '623370453d0515a24b10fd28': 'Profesionales de la salud',
        '623382d0307372b09a349e1e': 'Profesionales asociados de la salud',
        '6233909a69c00ed278da2098':
          'Trabajadores de cuidados personales en servicios de salud',
        '6233924406768a3c75fb97a3': 'Personal de apoyo y gestión sanitaria',
        '619a453469c00e98db28c887': 'Desastres relacionados con el clima',
        '623450bb307372f556349d92': 'Desastres provocados por el hombre',
        '62345349ea8890143ebbdcab': 'Desastres del movimiento de tierra',
        '630fdb8dc7585e000861eb74': 'Dolor durante el parto',
        '630fd98ac7585e000861eaec': 'Bacteriuria asintomática en embarazadas',
        '5d6848cb69c00e131b5a4cc0': 'Parto y alumbramiento',
        '630fd43db25ac300085beafb': 'Puerperio',
        '630fd787b25ac300085beba6': 'Desnutrición materna',
        '630fddefb128010008f0ed9f': 'Procedimientos obstétricos',
        other_populations: 'Otras poblaciones'
      },
      sdg3_populations: {
        '5cec39fcdbe4d24b26f43daf': 'Trastornos de la piel',
        '5cf20e7bc80dd41e57ba82b8': 'Enfermedades infecciosas',
        '5cfa863adbe4d25bcfbee2c7': 'Cáncer',
        '5d0b5a7bdbe4d25bcebee2a3': 'Enfermedades respiratorias',
        '5d14114adaeedf730426b7b8': 'Enfermedades del tejido conectivo',
        '5d234900daeedf1d3af33306': 'Trastornos dentales',
        '5d27332adaeedf1d3bf3330a': 'Condiciones hematológicas',
        '5d27427edaeedf1d3af33328': 'Enfermedades cardiovasculares',
        '5d3f3da269c00e04ee74f5ea': 'Condiciones neurológicas',
        '5d4322c869c00e198a009df3': 'Salud mental',
        '5d44e01769c00e198b009e39': 'Trastornos gastrointestinales',
        '5d5832c369c00e490b8f5553': 'Trastornos oculares',
        '5d5e8d9d69c00e507e1c8882': 'Trastornos genitourinarios',
        '5d7e231569c00e72a5188e3c': 'Trastornos de oído, nariz y garganta',
        '5d7f88af69c00e72a3188e15': 'Trastornos congénitos',
        '5d94f1d13c12ef641517c8d6': 'Trastornos endocrinos',
        '5d94f2a13c12ef641517c8d7': 'Trastornos nutricionales y metabólicos',
        '5d7d93b769c00e72a5188e34':
          'Obstetricia, ginecología y salud de la mujer',
        '61ae329e3d0515bfa88a791a': 'Sangrado obstétrico'
      },
      sdg3_interventions: {
        '5cec5dfdc80dd40a6f61aea0': 'Intervenciones farmacológicas',
        '5d1b60b4daeedf74ca4f4b51': 'Actividad física y fisioterapia',
        '5d247420daeedf1d3af3330e': 'Medicina alternativa y complementaria',
        '5d25202adaeedf1d3af33311': 'Procedimientos',
        '5d2523a9daeedf1d3af33312': 'Dieta e intervenciones dietéticas',
        '5d252f01daeedf1d3af33314': 'Intervenciones conductuales',
        '5d25e34fdaeedf1d3af33315': 'Salud pública',
        '5d25e37cdaeedf1d3af33316': 'Sistemas de salud',
        other_interventions: 'Otras intervenciones'
      },
      history: 'Historial',
      filters: {
        show_filters: 'Mostrar filtros',
        hide_filters: 'Ocultar filtros',
        active_filters: '({active} activos)',
        name: 'Filtros',
        year: {
          name: 'Año',
          placeholder: 'Todos',
          options: {
            last_year: lastYear(),
            last_two: `Desde ${lastTwoYears()}`,
            last_five: `Desde ${lastfiveYears()}`
          }
        },
        age: {
          name: 'Edad',
          placeholder: 'Todas',
          options: {
            children: 'Niños',
            adolescents: 'Adolescentes',
            adults: 'Adultos'
          }
        },
        institution: {
          name: 'Institución',
          placeholder: 'Todas',
          options: {
            who: 'Organización Mundial de la Salud',
            paho: 'Organización Panamericana de la Salud'
          }
        }
      },
      loading: 'cargando',
      see_episte: 'Ver en Epistemonikos',
      see_love: 'Ver en L·OVE',
      see_map: 'Ver mapa',
      map_button:
        'Selecciona una de las 13 metas ODS3 para ver el mapa de recomendaciones',
      toggle_guidelines: 'Ver título de directriz',
      view_guideline: 'Ver directriz',
      pending_translation: 'Traducción pendiente',
      total_recs: 'Total: {total} recomendaciones',
      platform_title: 'Recomendaciones GRADE de OPS/OMS para el ODS-3',
      show_more: 'Más detalles',
      fulltext: 'Texto completo',
      search_by_keyword: 'Buscar en BIGG-REC'
    },
    pico_builder: {
      no_hits: 'No se han encontrado resultados',
      sdg3_list_title: 'Seleccionar meta ODS3',
      treatment_list_title: 'Seleccionar intervención',
      population_list_title: 'Seleccionar población',
      show_more: 'Ver más',
      search: 'Buscar término'
    },
    recommendation: {
      history_message: 'Historial de la recomendación',
      superseded: 'Reemplazada',
      superseded_tooltip:
        'Esta recomendación fue reemplazada y ya no se encuentra vigente',
      thread_message:
        'Esta es una recomendación válida tambien disponible en las siguientes directrices:',
      newer_versions:
        'Esta recomendación fue reemplazada por la siguiente versión y no se encuentra vigente',
      older_versions: 'Esta recomendación reemplaza a la siguiente',
      appears_in: 'Ésta recomendación aparece en las siguientes guías clínicas'
    },
    guideline: {
      focus: 'Enfoque',
      focus_labels: {
        public_health: 'Salud pública',
        clinical_practice: 'Práctica clínica',
        health_policy_systems: 'Políticas en salud y sistemas'
      },
      users: 'Usuarios',
      users_labels: {
        healthcare_workers: 'Trabajadores de la salud',
        public_health_authorities: 'Autoridades en salud pública',
        health_facility_administrators: 'Administradores de centros de salud',
        occupational_health_authorities: 'Autoridades de salud ocupacional'
      },
      organization_type: 'Tipo de organización',
      organization_types: {
        intergov_not_profit: 'Intergubernamental, sin fines de lucro',
        gov_not_profit: 'Gubernamental, sin fines de lucro',
        for_profit: 'Con fines de lucro'
      },
      recs_included: 'Recomendaciones incluídas en ésta guía:',
      ext_links: 'Enlaces externos:',
      institution: 'Institución',
      links: 'Enlaces'
    },
    top_menu: {
      home: 'Inicio',
      terms:
        'Términos y condiciones de uso de los sitios y sistemas web de BIREME/OPS',
      about: 'Acerca de',
      disclaimer: 'Descargo de responsabilidad',
      disclaimer_content:
        'No nos hacemos responsables de la precisión o integridad del contenido de este sitio web. Aunque se han realizado esfuerzos y se han tomado recaudos razonables en su preparación, no asumimos responsabilidad alguna por errores u omisiones y es posible que se requiera consultar la fuente original de información. Las recomendaciones pueden actualizarse o cambiarse a medida que se acumulan las pruebas de investigación y la bibliografía de las directrices. La información proporcionada en este sitio web no sustituye el juicio profesional.',
      glossary: 'Glosario',
      glossary_tables: [
        {
          title:
            'Interpretacion de las recomendaciones fuertes y condicionales',fields:[
              {key:"Implications_for",label:"Implicaciones para"},
              {key:"Strong_recommendation",label:"Recomendación fuerte"},
              {key:"Conditional_recommendation",label:"Recomendación condicional"},
            ],
          content: [
            {
              Implications_for: 'Pacientes',
              Strong_recommendation:
                'La mayoría de los individuos en esta situación desearían el curso de acción recomendado y solo una pequeña proporción no lo desearía.',
              Conditional_recommendation:
                'La mayoría de los individuos desearían el curso de acción sugerido, pero muchos no. Los esquemas para la toma de decisiones compartidas pueden ser útiles para ayudar a los pacientes a tomar decisiones coherentes con sus riesgos individuales, valores y preferencias'
            },
            {
              Implications_for: 'Clínicos',
              Strong_recommendation:
                'La mayoría de los individuos en esta situación desearían el curso de acción recomendado y solo una pequeña proporción no lo desearía.',
              Conditional_recommendation:
                'Reconocer que opciones diferentes serían apropiadas para distintos pacientes, y que se debe ayudar para que cada paciente alcance una decisión de manejo consistente con sus valores y preferencias Los esquemas para la toma de decisiones compartidas pueden resultar útiles al momento de ayudar a los individuos en la toma de decisiones coherentes con sus valores y preferencias.'
            },
            {
              Implications_for: 'Desarrolladores de políticas',
              Strong_recommendation:
                'La recomendación se puede adoptar como política en la mayoría de las situaciones . El cumplimiento de esta recomendación de acuerdo con la directriz podría utilizarse como indicador de calidad o indicador de rendimiento.',
              Conditional_recommendation:
                'Formular políticas requerirá de debates importantes y la participación de muchas partes interesadas.Los indicadores de rendimiento tendrían que centrarse en el hecho que la deliberación adecuada acerca de las opciones de manejo ha tenido lugar.'
            },
            {
              Implications_for: 'Investigadores',
              Strong_recommendation:
                'La recomendación está respaldada por investigación creíble u otros juicios convincentes que hacen que la investigación adicional sea poco probable que altere la recomendación. En ocasiones, una recomendación fuerte se basa en una certeza baja o muy baja de la evidencia. En tales casos, la investigación adicional puede proporcionar información importante que altera las recomendaciones.',
              Conditional_recommendation:
                'Es probable que la recomendación se fortalezca (para futuras actualizaciones o adaptaciones) mediante investigaciones adicionales. Una evaluación de las condiciones y los criterios (y los juicios relacionados, la evidencia de la investigación y las consideraciones adicionales) que determinaron la recomendación condicional (en lugar de fuerte) ayudará a identificar posibles brechas en la investigación.'
            }
          ]
        }
      ],
      glossary_content: {
        point_1: {
          title: 'Recomendación',
          content:
            'Premisa accionable sobre la elección entre dos o más opciones de intervención en una población específica y en un entorno específico cuando sea relevante. Se deben especificar opciones alternativas [comparadores] cuando no sean evidentes. La declaración debe tener una dirección clara (a favor o en contra de una opción) y fuerza (por ejemplo, fuerte o condicional). Idealmente, la fuerza de la recomendación y la certeza de la evidencia de apoyo se establecen explícitamente. Una recomendación debe estar respaldada por un proceso de desarrollo deliberativo y estructurado. En particular, debe estar respaldado por una revisión sistemática o una evaluación de tecnología sanitaria de los factores que determinan su dirección y fuerza',
          id: '1'
        },
        point_2: {
          title: 'Recomendaciones de investigación',
          content:
            'Premisas accionables sobre el uso de opciones o intervenciones en una población específica, solo en el contexto de investigación. Las recomendaciones de investigación son apropiadas cuando se cumplen tres condiciones: i) la certeza de la evidencia disponible no permite que el grupo de guías emita una recomendación formal; ii) la investigación adicional tiene un gran potencial para reducir la incertidumbre sobre las consecuencias deseables o indeseables de la intervención; iii) y la investigación adicional se considera de buen valor para los costos anticipados. Las recomendaciones de investigación deben ser detalladas con respecto a las preguntas de investigación específicas que los investigadores deben abordar. La recomendación para la investigación puede ir acompañada de una recomendación explícita [fuerte] de no utilizar la intervención fuera del contexto de la investigación.',
          id: '2'
        },
        point_9: {
          title: 'GRADE',
          content:
            'La aproximación GRADE es un sistema para calificar la calidad del cuerpo de la evidencia en revisiones sistemáticas y otras síntesis de evidencia, como evaluaciones de tecnologías en salud y en guías, y formular recomendaciones en salud. La aproximación GRADE (de su sigla en inglés Grading of Recommendations Assessment, Development and Evaluation) ofrece un proceso transparente y estructurado para desarrollar y presentar los resúmenes de la evidencia, y para llevar a cabo los pasos que implica la formulación de las recomendaciones. Se puede utilizar para desarrollar guías de práctica clínica (GPC) y otras recomendaciones del área de la salud (p.ej. Salud pública, sistemas y políticas públicas y decisiones de cobertura en salud).',
          id: '9'
        },
        point_3: {
          title: 'Categorías oficiales de recomendaciones GRADE/clasificación.',
          text: {
            0: {
              id: '0',
              content:
                'Si el panel esta altamente confiado en el balance entre las consecuencias deseables e indeseables, hace una recomendación fuerte a favor (lo deseable supera a lo indeseable) o en contra (lo indeseable supera a lo deseable) de una intervención.',
              title: 'Recomendación fuerte o firme'
            },
            1: {
              id: '1',
              has_table: 'VERDADERO',
              title: 'Recomendación condicional, opcional o débil',
              content:
                'Si el grupo o panel de desarrollo de la guía no está muy seguro del balance entre las consecuencias deseables e indeseables para el uso previsto de la recomendación, ofrece una recomendación condicional. Esto puede ocurrir cuando la certeza de la evidencia es muy baja o baja, si los valores y preferencias, el costo, la equidad, la aceptabilidad, la viabilidad y otros factores pueden depender del entorno donde se implementa la intervención u opción o son variables o inciertas'
            },
            2: {
              id: '2',
              content:
                'Premisa accionable sobre el uso de opciones o intervenciones en una población específica, solo en el marco de participación en investigación',
              title: 'Recomendaciones de investigación'
            },

          },
          id: '3'
        },
        point_4: {
          title: 'Tipo de recomendaciones GRADE no oficiales',
          intro:
            'Las siguientes categorías no son oficiales en el enfoque GRADE, forman parte de clasificaciones alternativas definidas por algunos grupos de desarrollo de guías de la OMS y aprobadas por el comité de revisión de guías de la OMS.',
          text: {
            0: {
              id: '0',
              title: 'Recomendado',
              content:
                'Esta categoría indica que la intervención u opción debe implementarse.'
            },
            1: {
              id: '1',
              title: 'No recomendado o recomendado en contra',
              content:
                'Esta categoría indica que la intervención u opción no debe implementarse.'
            },
            2: {
              id: '2',
              title: 'Recomendado solo en contextos específicos',
              content:
                'Esta categoría indica que la intervención u opción es aplicable solo a la condición, entorno o población especificada en la recomendación, y solo debe implementarse en estos contextos'
            },
            3: {
              id: '3',
              title: 'Recomendado con seguimiento y evaluación específicos',
              content:
                'Esta calificación indica incertidumbre sobre la efectividad o aceptabilidad de una intervención, especialmente con respecto a contextos o condiciones particulares. Las intervenciones clasificadas como tales pueden considerarse para su implementación (incluso a gran escala), siempre que vayan acompañadas de un seguimiento y una evaluación específicos. Se debe prestar especial atención a cuestiones específicas sobre las que existen preocupaciones (como riesgos o daños) y sobre las que se dispone de poca o ninguna información relevante. La información sobre monitoreo y evaluación puede obtenerse de una variedad de fuentes, incluidos datos de rutina y datos de encuestas. El grupo desarrollador de la guía intentó especificar qué aspectos de las intervenciones requerían monitoreo y especificó los indicadores relevantes'
            },
            4: {
              id: '4',
              title:
                'Recomendado solo en el contexto de investigación rigurosa',
              content:
                'Esta categoría indica que existe importante incertidumbre sobre la intervención u opción. En tales casos, la implementación aún puede emprenderse a gran escala, siempre que adopte la forma de investigación que sea capaz de abordar preguntas sin respuesta e incertidumbres relacionadas tanto con la efectividad de la intervención u opción como con su aceptabilidad y viabilidad.'
            },
            5: {
              id: '5',
              title: 'Categoría MEC 1',
              content:
                'Esta categoría no es oficial en el enfoque GRADE, constituye parte de una clasificación alternativa definida por el grupo de desarrollo de la guía y aprobada por el comité de revisión de la guía. Esta categoría indica una condición para la cual no hay restricción para el uso del método anticonceptivo.'
            },
            6: {
              id: '6',
              title: 'Categoría MEC 2',
              content:
                'Esta categoría no es oficial en el enfoque GRADE, constituye parte de una clasificación alternativa definida por el grupo de desarrollo de la guía y aprobada por el comité de revisión de la guía. Esta categoría indica una condición donde las ventajas de usar el método anticonceptivo generalmente superan los riesgos teóricos o probados'
            },
            7: {
              id: '7',
              title: 'Categoría MEC 3',
              content:
                'Esta categoría no es oficial en el enfoque GRADE, constituye parte de una clasificación alternativa definida por el grupo de desarrollo de la guía y aprobada por el comité de revisión de la guía. Esta categoría indica una condición en la que los riesgos teóricos o probados suelen superan las ventajas de utilizar el método anticonceptivo.'
            },
            8: {
              id: '8',
              title: 'Categoría MEC 4',
              content:
                'Esta categoría no es oficial en el enfoque GRADE, constituye parte de una clasificación alternativa definida por el grupo de desarrollo de la guía y aprobada por el comité de revisión de la guía. Esta categoría indica una condición que representa un problema de salud inaceptable. riesgo si se utiliza el método anticonceptivo.'
            },

          },
          id: '4'
        },
        point_5: {
          title: 'Certeza en la evidencia',
          intro:
            'Certeza de que una estimación de asociación o efecto es correcta o, mejor, de que un efecto verdadero se encuentra en un lado de un umbral específico o dentro de un rango elegido. La certeza de la evidencia, también llamada confianza en las estimaciones de los efectos, calidad de la evidencia, fuerza de la evidencia o niveles de evidencia se expresa en cuatro categorías: alta, moderada, baja y muy baja, según la evaluación de las siguientes preguntas de señalización para un cuerpo de evidencia (entre comillas los dominios específicos abordados por los científicos):',
          text: {
            0: {
              id: '0',
              content:
                '¿Están bien hechos los estudios de investigación? "Limitaciones en el detalle del diseño y ejecución del estudio o también llamado riesgo de sesgo o validez interna'
            },
            1: {
              id: '1',
              content:
                '¿Los resultados son consistentes a través de los estudios? "Inconsistencia'
            },
            2: {
              id: '2',
              content:
                '¿Cuán directa es la relación de los resultados con nuestra pregunta? "Comparaciones indirectas, aplicabilidad, generalizabilidad, validez externa'
            },
            3: {
              id: '3',
              content:
                '¿El tamaño del efecto es preciso- debido a un error aleatorio? "Imprecisión'
            },
            4: {
              id: '4',
              content:
                '¿Son estos todos los estudios que se han realizado? "Sesgo de publicación'
            },
            5: {
              id: '5',
              content:
                '¿Hay algo más que nos haga estar particularmente seguros? "Efectos de gran magnitud, predictores del peor escenario y aún conclusiones sólidas, relación exposición-efecto'
            },

          },
          id: '5'
        },
        point_6: {
          title: 'Certeza en la estimación de efecto',
          content:
            'Esto expresa la certeza en el efecto de una intervención al considerar el cuerpo de la evidencia y es similar a la certeza de la evidencia.',
          id: '6'
        },
        point_7: {
          title: 'Mapa de recomendaciones',
          content:
            'Una herramienta para organizar recomendaciones a partir de una serie de directrices sobre una determinada afección, reportar características en torno a la evidencia y los juicios que informan la recomendación y destacar grupos y brechas en la disponibilidad de recomendaciones, en un esquema visual digital. Los mapas de recomendaciones están organizados por los componentes de la pregunta PICO (Población, Intervenciones, Comparador, Resultado) que guió el desarrollo de la recomendación respectiva.',
          id: '7'
        },
        point_8: {
          title: 'Glosario del Manual GRADE',
          content:
            'https://gdt.gradepro.org/app/handbook/translations/es/handbook.html#h.20xfydz',
          id: '8'
        }
      },
      doc_title: ''
    },
    map: {
      recommendations_map: 'Mapa de recomendaciones',
      back_button: 'Volver atrás'
    },
    footer: {
      powered: 'Desarrollado por',
      privacy_policy: {

        content_1: 'example {link}',
        link: 'url@gmail',
        main_title: 'Política de Privacidad'
      },
      contact: {
        name: 'Contacto',
        text:"Para preguntas o consultas, por favor contáctenos al",
        content: {
          title_1: 'Título Ejemplo'
        }
      },
      terms_and_conditions: {
        text: [
          {
            id: '0',
            content:
              'El contenido de este sitio o el vinculado desde la base de datos Epistemonikos ("Epistemonikos") a otros sitios se proporciona de forma gratuita y abierta, sin que se establezca o exista un vínculo contractual entre los propietarios y / o administradores de este sitio web y los participantes y usuarios del proyecto. El contenido propiedad de Epistemonikos que se pone a disposición de los usuarios se puede utilizar libremente, sujeto a los términos y condiciones de su esquema de licencia. El contenido de terceros vinculados a Epistemonikos, se puede utilizar de acuerdo con los requisitos de licencia del propietario de los derechos del contenido vinculado.'
          },
          {
            id: '1',
            content:
              'Solo la versión en inglés de todos los títulos y resúmenes de los documentos disponibles, así como las traducciones marcadas con una estrella, pueden considerarse confiables. Sin embargo, con respecto a todos los documentos y traducciones, Epistemonikos, sus colaboradores, administradores, programadores, patrocinadores, investigadores y, en general, cualquier persona relacionada con Epistemonikos o que trabaje en el proyecto, no actúan como garantes ni son responsables de ninguna manera con respecto a la exactitud, la integridad o el uso de la información proporcionada, que es propiedad de sus respectivos titulares de derechos.'
          },
          {
            id: '2',
            content:
              'Epistemonikos no admite, respalda ni recomienda ningún producto, tratamiento o servicio comercial. Los puntos de vista y opiniones de los autores individuales de la información que se pone a disposición no reflejan necesariamente la opinión de Epistemonikos, ni de sus colaboradores, administradores, programadores, patrocinadores, investigadores y, en general, de cualquier persona relacionada con Epistemonikos o que trabaje en el proyecto. Por lo tanto, no pueden utilizarse para fines comerciales o para respaldar un producto, tratamiento o servicio específico.'
          },
          {
            id: '3',
            content:
              'Epistemonikos no proporciona consejos, consultas o recomendaciones de salud específicos, sino que trata de proporcionar al público en general, de forma sistematizada, la mejor y más confiable información disponible para comprender mejor los problemas de la atención médica. Epistemonikos no asume ninguna obligación legal o responsabilidad derivada de la aplicación de la información a situaciones reales. La información puesta a disposición del público por Epistemonikos, especialmente sobre farmacoterapia y procedimientos quirúrgicos, no sustituye, reemplaza ni complementa de ninguna manera el consejo proporcionado por los médicos u otros profesionales de la salud. Cualquier uso de la información contenida en Epistemonikos es responsabilidad exclusiva del usuario.'
          },
          {
            id: '4',
            content:
              'Epistemonikos.org es un proyecto colaborativo sin fines de lucro mantenido por la Fundación Epistemonikos. Todo su contenido y características están disponibles de forma gratuita.'
          }
        ],
        intellectual_property: {
          text: [
            {
              id: '0',
              content:
                'Epistemonikos enlaza a contenidos de otros sitios web y bases de datos, principalmente de revistas científicas seriadas. Dichos enlaces dan acceso a contenidos de terceros, en la medida que éstos lo permitan. Los contenidos ubicados en sitios enlazados no son modificados de ninguna forma. Los derechos de autor de los contenidos a los cuales Epistemonikos enlaza son de propiedad de su titular respectivo.'
            },
            {
              id: '1',
              content:
                'Para mayor referencia, Epistemonikos puede proveer fragmentos del contenido enlazado, efectuado con fines de investigación y académicos, incluyéndose una referencia a su fuente, título y autor.'
            },
            {
              id: '2',
              content:
                'Toda marca registrada, para productos, servicios, mixtas, marcas colectivas, diseños industriales, patentes, u otros derechos de propiedad industrial que puedan referirse, comentarse o citarse en los contenidos de Epistemonikos, o en los contenidos enlazados, son de propiedad de sus respectivos titulares. A menos que se indique expresamente, Epistemonikos no tiene relación ni vínculo contractual alguno con los titulares de los derechos respectivos.'
            }
          ],
          laws: [
            {
              text: [
                {
                  id: '0',
                  content:
                    'Epistemonikos es un proyecto colaborativo destinado a publicitar y facilitar el acceso al conocimiento de información médica basada en evidencia, sin fines de lucro. El uso de la información enlazada se efectúa en cumplimiento de las leyes y tratados de propiedad intelectual vigentes en la República de Chile.'
                },
                {
                  id: '1',
                  content:
                    'If you own the copyright of a work contained or referred to in the website, and believe the Epistemonikos’ use of it is infringing, please contact contact@epistemonikos.org, stating your rights to the work, its location in the website, the rights allegedly infringed and, if applicable, the user that provides the infringing content. If justified, in compliance with Law 17.336 of Intellectual Property, the infringing content will be removed.'
                }
              ],
              name: 'Ley 17.336 de Propiedad Intelectual (Chile)'
            }
          ],
          title: 'Propiedad intelectual e industrial'
        },
        jurisdiction: {
          text: [
            {
              id: '0',
              content:
                'Toda la información publicada, enlazada y en general puesta a disposición de los usuarios por Epistemonikos se efectúa en cumplimiento de las leyes de la República de Chile. Toda controversia, conflicto, o reclamo relacionado con el sitio web y sus contenidos, será resuelto en cumplimiento de las leyes vigentes de la República de Chile que sean aplicables.'
            }
          ],
          title: 'Jurisdicción'
        },
        main_title: 'Términos y Condiciones'
      }
    },
    modals: {
      contact: {
        title: 'Contacto',
        content:
          'Para preguntas o consultas con respecto a esta política de privacidad, sírvase comunicarse con nosotros en bir.online@paho.org'
      }
    },
    undefined: 'es'
  },
  fr: {
    general: {
      doc_title: 'BIGG-REC - Recommandations GRADE',
      home: 'Domicile',
      map: 'Carte',
      year: 'Année',
      clear: 'Effacer',
      institutions: {
        WHO: 'Organisation Mondiale de la Santé',
        PAHO: 'Organisation Pan-américaine de la Santé'
      },
      recommendation: 'Recommandation | Recommandations',
      guideline: 'Ligne directrice | Lignes directrices',
      direction: 'Orientation',
      rec_strength: 'Solidité de la recommandation',
      rec_type_labels: {
        strong: 'Fort',
        conditional: 'Conditionnel',
        recommended: 'Recommandé',
        not_recommended: 'Non recommandé',
        research_context: 'Recommandé dans le cadre de recherches rigoureuses',
        context_specific: 'Recommandé dans des contextes spécifiques',
        targeted_monitoring_context:
          "Recommandé dans le cadre d'un suivi et d'une évaluation ciblés",
        mec_1: 'CEM 1',
        mec_2: 'CEM 2',
        mec_3: 'CEM 3',
        mec_4: 'CEM 4'
      },
      rec_type_tooltips: {
        strong:
          "Une recommandation peut être forte ou conditionnelle. Lorsqu'une recommandation est forte, la plupart des personnes voudront la suivre. Lorsqu'une recommandation est conditionnelle, la plupart des personnes voudront la suivre, mais devront peut-être d'abord en discuter avec leur professionnel de la santé.",
        conditional:
          "Une recommandation peut être forte ou conditionnelle. Lorsqu'une recommandation est forte, la plupart des personnes voudront la suivre. Lorsqu'une recommandation est conditionnelle, la plupart des personnes voudront la suivre, mais devront peut-être d'abord en discuter avec leur professionnel de la santé.",
        recommended:
          "Cette catégorie indique que l'intervention ou l'option doit être mise en œuvre. Cette catégorie n'est pas officielle dans l'approche GRADE, elle fait partie d'une classification alternative définie par le groupe élaborant les lignes directrices et approuvée par le comité de révision des lignes directrices.",
        not_recommended:
          "Cette catégorie indique que l'intervention ou l'option ne doit pas être mise en œuvre. Cette catégorie n'est pas officielle dans l'approche GRADE, elle fait partie d'une classification alternative fournie par le groupe élaborant les lignes directrices et approuvée par le comité de révision des lignes directrices.",
        context_specific_recommendation:
          "Cette catégorie indique que l'intervention ou l'option s'applique uniquement à la condition, au contexte ou à la population spécifiés dans la recommandation, et ne doit être mise en œuvre que dans ces situations.",
        research_context_recommendation:
          "Cette catégorie indique qu'il existe une incertitude importante concernant l'intervention ou l'option. Dans de tels cas, la mise en œuvre peut encore avoir lieu à grande échelle, à condition qu'elle prenne la forme d'une recherche capable de répondre aux questions restées sans réponse et aux incertitudes liées à l'efficacité de l'intervention ou de l'option, à son acceptabilité et à sa faisabilité. Cette catégorie n'est pas officielle dans l'approche GRADE, elle fait partie d'une classification alternative définie par le groupe élaborant les lignes directrices et approuvée par le comité de révision des lignes directrices.",
        recommended_in_the_context_of_targeted_monitoring_and_evaluation:
          "Cette note indique une incertitude quant à l'efficacité ou l'acceptabilité d'une intervention, en particulier en ce qui concerne des situations ou des conditions particulières. Cette catégorie n'est pas officielle dans l'approche GRADE, elle fait partie d'une classification alternative définie par le groupe élaborant les lignes directrices et approuvée par le comité de révision des lignes directrices.",
        mec_category_1:
          "Cette catégorie n'est pas officielle dans l'approche GRADE, elle fait partie d'une classification alternative définie par le groupe élaborant les lignes directrices et approuvée par le comité de révision des lignes directrices. Cette catégorie indique une condition médicale dans laquelle il n'y a aucune restriction sur l'utilisation de la méthode contraceptive.",
        mec_category_2:
          "Cette catégorie n'est pas officielle dans l'approche GRADE, elle fait partie d'une classification alternative définie par le groupe élaborant les lignes directrices et approuvée par le comité de révision des lignes directrices. Cette catégorie indique une condition médicale dans laquelle les avantages de l'utilisation de la méthode contraceptive l'emportent généralement sur les risques théoriques ou avérés.",
        mec_category_3:
          "Cette catégorie n'est pas officielle dans l'approche GRADE, elle fait partie d'une classification alternative définie par le groupe élaborant les lignes directrices et approuvée par le comité de révision des lignes directrices. Cette catégorie indique une condition médicale dans laquelle les risques théoriques ou avérés l'emportent généralement sur les avantages de l'utilisation de la méthode contraceptive.",
        mec_category_4:
          "Cette catégorie n'est pas officielle dans l'approche GRADE, elle fait partie d'une classification alternative définie par le groupe élaborant les lignes directrices et approuvée par le comité de révision des lignes directrices. Cette catégorie indique une condition médicale qui représente un risque inacceptable pour la santé si la méthode contraceptive est utilisée."
      },
      type: 'Type',
      coe: 'Certitude de la preuve',
      coe_tooltip:
        "La certitude de la preuve est une évaluation du niveau de confiance qui peut être attribué au fait que les estimations de l'effet soit correctes.",
      coe_labels: {
        high: 'haute',
        moderate: 'modérée',
        low: 'basse',
        'very low': 'très basse'
      },
      notes: 'Remarques et commentaires',
      population: 'Population | Populations',
      intervention: 'Intervention | Interventions',
      sdg3_goals: 'Cible ODD3 | Cibles ODD3',
      sdg3_header_1: 'Naviguer',
      sdg3_header_2: 'Cibler:',
      sdg3_targets: {
        '6167009e06768adee26a276f': 'Santé maternelle',
        '616700b43552581d99259cbd': 'Santé infantile',
        '616700c83d0515512662039e': 'Maladies transmissibles',
        '616700dfea88905b6d651ab4': 'Maladies non transmissibles',
        '6167019d06768adee26a27b6': 'Consommation de substances psychoactives',
        '616701b83d051551266203e3': 'Santé sexuelle et reproductive',
        '616701f0ea88905b6d651b03': 'Santé universelle',
        '616701fd06768adee26a27d3': "Pollution de l'air, de l'eau et du sol",
        '6167020b3d05155126620408': 'Personnel(s) de santé',
        '6167021bea88905b6d651b12': 'Risques sanitaires nationaux et mondiaux',
        '6167022606768adee26a27e8': 'Accidents de la route',
        '616702393d05155126620415': 'Lutte anti-tabac',
        '61670245ea88905b6d651b27':
          'Développement de vaccins et de médicaments',
        '6232d8b806768a45cafb964b': 'Allaitement maternel',
        '5d25e658daeedf1d3af3331b': 'Sevrage tabagique',
        '623302a1355258bf19d48919': 'Politiques de lutte anti-tabac',
        '623d860d355258962733d076':
          'Interventions Eau, Assainissement et Hygiène (WASH)',
        '5d31a969daeedf1d3af3333f': "Conditions d'accouchement",
        '61e093773d0515f69ae5f25f': "Services d'avortement sécurisé",
        '5dd7a70469c00e7ae9856041': 'Conditions financières',
        '5dd7eb313c12ef61034d00f3': 'Modalités de gestion',
        '5d4c5d0769c00e0d5edaea4b': "Techniques d'anesthésie",
        '61969b2aea88903ea3a9cb6b': 'Soins prénataux',
        '619d5dc2307372254d2fb70c': 'Planification familiale',
        '622630fb355258e04d57f2b5': 'Cannabis',
        '62263163ea8890a65d455607': 'Alcool',
        '622633c5307372a476759d26': 'Opioïdes',
        '62263906355258e04d57f50e': 'Sédatifs-hypnotiques',
        '62263c23ea8890b83445534b': 'Cocaïne',
        '62263cdb06768a27badd846a': 'Amphétamines',
        '6226424eea8890cac54552db': 'Hallucinogènes',
        '62264853307372b78b75a001': 'Substances inhalées',
        '6232f74d3552589cf3d489df':
          'Exposition professionnelle ou environnementale',
        '62af88f506768ad40b76ca75': 'Exposition des enfants aux substances',
        '61e02fc03552586c64987855': 'Morsure de serpent',
        '61e0927e06768a05b24df5ea': 'Accidents de la circulation',
        '6230e95469c00e8b53a62277': 'Piétons',
        '6230ea4d69c00e8b53a622be': 'Conducteurs de véhicules à moteur',
        '6230f589355258f491a91beb': 'Cyclistes',
        '6230f744ea889066d8cf39a5': "Victimes d'accidents de la route",
        '5e18816a19a11a14c75ed9a1': 'Maux de tête',
        '5e18816a19a11a14c75ed9a2': 'Migraine',
        '5d7e803069c00e72a5188e4f':
          'Fausse-couche, mortinaissance et avortement',
        '61ae22493d05159df98a787c':
          "Progression anormale du travail d'accouchement",
        '61ae228e06768afaf4086a69': 'Rupture utérine',
        '61ae329e3d0515bfa88a791a': 'Saignement obstétrical',
        '61ae3b98ea88906f40808bde': 'Rupture des membranes avant travail',
        '61df380b307372c1b76fddfa': 'Travail prolongé',
        '61df3c89307372c1b76fdf53': 'Accouchement instrumentalisé',
        '61df3dc73d0515f181e5f312': 'Césarienne',
        '61df40563552585a4198768a': 'Déclenchement du travail',
        '61df4193307372c1b76fe0b4': 'Traumatisme périnéal',
        '61df4312307372d5566fdd1a': 'Traumatisme obstétrical',
        '61df4fab3552589b2c98759a': 'Troubles hypertensifs de la grossesse',
        '61ae37433552583a066132f2': 'Infections maternelles',
        '61ae9e133d0515be1f8a7b58': "Grand pour l'âge gestationnel",
        '61df49fb3d05152368e5f105': 'Thromboembolie obstétricale',
        '61ae05fd355258b08e6134d0': 'Grossesse extra-utérine',
        '60eefcdd3d0515c51d3f02ae': 'Adolescentes enceintes',
        '5d062d5fc80dd41e58ba8459': 'Femme enceinte',
        '6269b62a3552584246eb4bb5': 'Diabète et grossesse',
        '5cf20ea0c80dd41e58ba8259': 'Hépatite virale',
        '5d0a8a04c80dd41e57ba89a0': 'Choléra',
        '5d0a8a85dbe4d25bcfbee78f': 'Typhoïde et paratyphoïde',
        '61e005a2307372bffc6fdf9b': 'Diarrhée infectieuse',
        '5d0a9c60dbe4d25bcfbee797': 'Tuberculose',
        '5d0b446adbe4d25bcfbee7fd': 'Septicémie',
        '5d4a7cd869c00e69c91e5ff8': 'Méningite',
        '5d0b4ec7dbe4d25bcfbee800': 'Dengue',
        '5d52927869c00e3de733d820': 'VIH',
        '5d52d6bf69c00e3de733d835': 'Pneumonie',
        '5d6bcb6369c00e39658ba0a2': 'Paludisme',
        '5d7f530269c00e72a5188e5e': 'Maladie de Chagas',
        '5da2f85069c00e78ea7056d5': 'Brucellose',
        '5da2fbf63c12ef5722a1d717': 'Infection à virus Zika',
        '61e01ad006768a739d4df424': 'Chikungunya',
        '63c30f7dc96e6d00081e1b35': 'Infection à Taenia solium',
        '5e7fce7e3d05156b5f5e032a': 'COVID-19 [feminin]',
        '618524ca307372a53541f22e': 'Urgences infectieuses de santé publique',
        '628667843552584bba67f67a': 'Variole du singe',
        '61967a183d051589e00a0104': 'Fièvre hémorragique de Crimée-Congo',
        '619697e869c00e50e228c6c1': 'Peste',
        '61df52ea69c00e31667ed778': 'Anthrax',
        '61df53973552589d7298765a': 'Ascaridiose',
        '61e00914ea8890a3bcfe632e': 'Typhus épidémique',
        '61e075323552580bfd9877ea': 'Typhus des broussailles',
        '61e00ae869c00ecf047ed7a1': 'Gnathostomose',
        '61e00c83307372d43a6fdda8': 'Pédiculose du cuir chevelu',
        '61e0147f3552582e469878a6': 'Histoplasmose',
        '61e01531307372d43a6fdfe9': 'Encéphalite japonaise',
        '61e016663552584ac49875ba': 'Leishmaniose',
        '61e0174dea8890b596fe633c': 'Rougeole',
        '61e01812307372e5dc6fdd04': 'Mélioïdose',
        '61e0191d3d0515e90ae5f1bf': 'Maladies tropicales négligées',
        '61e0773706768ae17b4df63b': 'Strongyloïdose',
        '61e02d0169c00e0b937ed7e6': 'Gale',
        '61e073d2ea88903585fe6172': 'Paracoccidioïdomycose',
        '61e076a3ea88903585fe6231': 'Variole',
        '61e077fa06768ae17b4df66a': 'Encéphalite à tiques',
        '61e0788e30737252756fe032': 'Trichomonose',
        '5cfa863adbe4d25bcfbee2c7': 'Cancer',
        '62a904023d0515e3d541fcc3': 'Cancer pédiatrique',
        '5d0b5bdedbe4d25bcfbee803': 'MPOC',
        '5d7520f369c00e39658ba0fa': 'Asthme',
        '62a91523ea88903c2895b27b': 'Infections respiratoires pédiatriques',
        '5d14114adaeedf730426b7b8': 'Maladies des tissus conjonctifs',
        '5d234900daeedf1d3af33306': 'Problèmes dentaires',
        '5d27332adaeedf1d3bf3330a': 'Affections hématologiques',
        '5da2ea543c12ef5722a1d710': 'Drépanocytose',
        '61e08aee307372777b6fdd98': 'Thalassémie',
        '5d2756d7daeedf1d3af3332a': 'Cardiopathie ischémique',
        '5d3f38f669c00e04ee74f5e7': 'Thrombose veineuse',
        '5d3f397e69c00e04ee74f5e9': 'Maladies cérébro-vasculaires',
        '5d3f3aec69c00e04ef74f5c8': 'Hypertension',
        '5d7e2ac769c00e72a5188e41': 'Épilepsie',
        '5d7e2be069c00e72a5188e42': 'Sclérose en plaques',
        '5d99f1f03c12ef11577a0053': 'Maladie de Parkinson',
        '62a90e9eea88903c2895b0d2': "Troubles neurologiques de l'enfant",
        '5d4322c869c00e198a009df3': 'Santé mentale',
        '5d4322f769c00e198b009e1b': 'Démence',
        '5d43245e69c00e198b009e1e': "Trouble lié à l'utilisation de substances",
        '5da097e33c12ef4de3dce666': 'Violence domestique',
        '5da097e33c12ef4de3dce667': 'Violence sexuelle',
        '5da097e33c12ef4de3dce668': 'Maltraitance infantile',
        '62a90f8e06768aeab576cc5d': "Santé mentale de l'enfant",
        '5d44e01769c00e198b009e39': 'Problèmes gastro-intestinaux',
        '5d52974169c00e3de733d823': 'Cirrhose hépatique',
        '5d5832c369c00e490b8f5553': 'Troubles de la vue',
        '5d5e8e3669c00e507e1c8883': 'Maladie rénale chronique',
        '5d9680db69c00e73b4b22508': 'Stérilité',
        '5d7e231569c00e72a5188e3c': 'Troubles de la sphère ORL',
        '5d7f88af69c00e72a3188e15': 'Maladies congénitales',
        '5d94f1d13c12ef641517c8d6': 'Troubles endocriniens',
        '5d52919a69c00e3de733d81f': 'Diabète',
        '5d94f2a13c12ef641517c8d7': 'Troubles métaboliques et nutritionnels',
        '5cf00956dbe4d25bcfbedf17': 'Surpoids et obésité',
        '62a902c6307372b0385ca5db': 'Obésité infantile',
        '6231aeb73073724b9bd88b7d': 'Maladies infantiles',
        '6231af3d06768abaa9e445b2': 'Santé du nouveau-né',
        '6232ebdbea8890f8d8bbde6d': 'Prématurité/faible poids à la naissance',
        '6232f051307372ecd7349e7c': 'Asphyxie périnatale',
        '61df363006768a5c4c4df45a': "Surveillance du travail d'accouchement",
        '60c8b64006768aab069c540d': 'Résistance auxantimicrobiens',
        '60ef101206768ac0e77738d8': 'Maladies et infections sexuellement transmissibles',
        '5d0b46cec80dd41e57ba89c1': 'Syphilis',
        '5d7e31f669c00e72a5188e45':
          'Traumatismes, empoisonnements et blessures',
        '6232f489307372009d349b86': 'Empoisonnement accidentel',
        '61e0866106768af3424df682': 'Mode de vie sédentaire',
        '61e08a2569c00ef2ea7ed7d2': 'Consommation de sel',
        '6208400dea88909b3f0a3c97': 'Pollution des eaux',
        '620840bb355258cf6dfd0138': "Qualité de l'air",
        '620840e306768aef5a232488': 'Pollution des sols',
        '623370453d0515a24b10fd28': 'Professionnels de santé',
        '623382d0307372b09a349e1e': 'Auxiliaires médicaux',
        '6233909a69c00ed278da2098':
          'Assistants de soins personnels dans les services de santé',
        '6233924406768a3c75fb97a3':
          'Personnel de soutien et de gestion de la santé',
        '619a453469c00e98db28c887': "Catastrophes d'origine climatique",
        '623450bb307372f556349d92': "Catastrophes provoquéee par l'homme",
        '62345349ea8890143ebbdcab':
          'Catastrophes liées aux mouvements de la terre',
        '630fdb8dc7585e000861eb74': "Douleur liée au travail d'accouchement",
        '630fd98ac7585e000861eaec':
          'Bactériurie asymptomatique pendant la grossesse',
        '5d6848cb69c00e131b5a4cc0': 'Travail et accouchement',
        '630fd43db25ac300085beafb': 'Période post-partum',
        '630fd787b25ac300085beba6': 'Malnutrition maternelle',
        '630fddefb128010008f0ed9f': 'Procédures obstétricales',
        other_populations: 'Autres populations'
      },
      sdg3_populations: {
        '5cec39fcdbe4d24b26f43daf': 'Maladies dermatologiques',
        '5cf20e7bc80dd41e57ba82b8': 'Maladies infectieuses',
        '5cfa863adbe4d25bcfbee2c7': 'Cancer',
        '5d0b5a7bdbe4d25bcebee2a3': 'Maladies respiratoires',
        '5d14114adaeedf730426b7b8': 'Maladies des tissus conjonctifs',
        '5d234900daeedf1d3af33306': 'Problèmes dentaires',
        '5d27332adaeedf1d3bf3330a': 'Affections hématologiques',
        '5d27427edaeedf1d3af33328': 'Maladies cardiovasculaires',
        '5d3f3da269c00e04ee74f5ea': 'Maladies neurologiques',
        '5d4322c869c00e198a009df3': 'Santé mentale',
        '5d44e01769c00e198b009e39': 'Problèmes gastro-intestinaux',
        '5d5832c369c00e490b8f5553': 'Troubles de la vue',
        '5d5e8d9d69c00e507e1c8882': 'Troubles génito-urinaires',
        '5d7e231569c00e72a5188e3c': 'Troubles de la sphère ORL',
        '5d7f88af69c00e72a3188e15': 'Maladies congénitales',
        '5d94f1d13c12ef641517c8d6': 'Troubles endocriniens',
        '5d94f2a13c12ef641517c8d7': 'Troubles métaboliques et nutritionnels',
        '5d7d93b769c00e72a5188e34':
          'Obstétrique, gynécologie et santé des femmes',
        '61ae329e3d0515bfa88a791a': 'Hémorragie obstétricale'
      },
      sdg3_interventions: {
        '5cec5dfdc80dd40a6f61aea0': 'Traitements pharmacologiques',
        '5d1b60b4daeedf74ca4f4b51': 'Activité physique et physiothérapie',
        '5d247420daeedf1d3af3330e': 'Médecine complémentaire et alternative',
        '5d25202adaeedf1d3af33311': 'Procédures',
        '5d2523a9daeedf1d3af33312': 'Régimes et interventions diététiques',
        '5d252f01daeedf1d3af33314': 'Interventions comportementales',
        '5d25e34fdaeedf1d3af33315': 'Santé publique',
        '5d25e37cdaeedf1d3af33316': 'Systèmes de santé',
        other_interventions: 'Autres interventions'
      },
      history: 'Histoire',
      filters: {
        show_filters: 'Afficher les filtres',
        hide_filters: 'Masquer les filtres',
        active_filters: '({actif} actif)',
        name: 'Filtres',
        year: {
          name: 'Année',
          placeholder: 'Tous',
          options: {
            last_year: lastYear(),
            last_two: `Depuis  ${lastTwoYears()}`,
            last_five: `Depuis  ${lastfiveYears()}`
          }
        },
        age: {
          name: 'Âge',
          placeholder: 'Toutes',
          options: {
            children: 'Enfants',
            adolescents: 'Adolescents',
            adults: 'Adultes'
          }
        },
        institution: {
          name: 'Institution',
          placeholder: 'Toutes',
          options: {
            who: 'Organisation Mondiale de la Santé',
            paho: 'Organisation Pan-américaine de la Santé'
          }
        }
      },
      loading: 'Chargement',
      see_episte: 'Voir à Epistemonikos',
      see_love: 'Voir à L·OVE',
      see_map: 'Voir la carte',
      map_button:
        "Sélectionner l'une des 13 cibles de l'ODD-3 pour voir la carte des recommandations",
      toggle_guidelines: 'Afficher le titre de la ligne directrice',
      view_guideline: 'Voir la ligne directrice',
      pending_translation: 'Traduction en attente',
      total_recs: 'Total : {total} recommandations',
      platform_title: "Recommandations GRADE de l'OPS/OMS pour l'ODD-3",
      show_more: 'Plus de détails',
      fulltext: 'Texte complet',
      search_by_keyword: 'Rechercher dans BIGG-REC'
    },
    pico_builder: {
      no_hits: 'Aucun résultat trouvé',
      sdg3_list_title: 'Sélectionner la cible ODD3',
      treatment_list_title: "Sélectionner l'intervention",
      population_list_title: 'Sélectionner la population',
      show_more: 'Afficher plus',
      search: 'Rechercher le mot-clé'
    },
    recommendation: {
      history_message: 'Historique des recommandations',
      superseded: 'Remplacé',
      superseded_tooltip:
        "Cette recommandation a été remplacée et n'est plus valide.",
      thread_message:
        "Il s'agit d'une recommandation valide également disponible dans les lignes directrices suivantes :",
      newer_versions:
        "Cette recommandation a été remplacée par une nouvelle version et n'est plus valide",
      older_versions: 'Cette recommandation remplace la suivante',
      appears_in:
        'Cette recommandation apparaît dans les lignes directrices suivantes'
    },
    guideline: {
      focus: 'Focus',
      focus_labels: {
        public_health: 'Santé publique',
        clinical_practice: 'Pratique clinique',
        health_policy_systems: 'Politiques et systèmes de santé'
      },
      users: 'Utilisateurs',
      users_labels: {
        healthcare_workers: 'Professionnels de santé',
        public_health_authorities: 'Autorités de santé publique',
        health_facility_administrators:
          "Responsables d'établissements de santé",
        occupational_health_authorities:
          'Autorités responsables de la santé au travail'
      },
      organization_type: "Type d'organisation",
      organization_types: {
        intergov_not_profit: 'Intergouvernementale à but non lucratif',
        gov_not_profit: 'Gouvernement à but non lucratif',
        for_profit: 'A but lucratif'
      },
      recs_included: 'Recommandations incluses dans cette ligne directrice :',
      ext_links: 'Liens externes :',
      institution: 'Institution',
      links: 'Liens'
    },
    top_menu: {
      home: 'Accueil',
      terms:
        "Conditions générales d'utilisation des sites internet et des systèmes de BIREME/PAHO",
      about: 'À propos de',
      disclaimer: 'Clause de non-responsabilité',
      disclaimer_content:
        "Nous n'assumons aucune responsabilité quant à l'exactitude ou l'exhaustivité du contenu de ce site internet. Bien qu'une diligence et des efforts raisonnables aient été apportés à sa préparation, nous n'assumons aucune responsabilité quant aux erreurs ou omissions et une consultation de la source originale d'information peut s'avérer nécessaire. Les recommandations peuvent être mises à jour ou modifiées à mesure que les données de recherche et la documentation sur les lignes directrices s'accumulent. Les informations fournies sur ce site Web ne sont pas destinées à remplacer les conseils d'un professionnel.",
      glossary: 'Glossaire',
      glossary_tables: [
        {
          title: 'Interprétation des recommandations fortes et conditionnelles',
          fields:[
            {key:"Implications_for",label:"Implications pour"},
            {key:"Strong_recommendation",label:"Forte recommandation"},
            {key:"Conditional_recommendation",label:"Recommandation conditionnelle"},
          ],
          content: [
            {
              Implications_for: 'Patients',
              Strong_recommendation:
                "La plupart des personnes dans cette situation voudraient suivre le plan d'action recommandé, et seule une petite proportion ne le ferait pas.",
              Conditional_recommendation:
                "La plupart des personnes dans cette situation voudraient suivre le plan d'action suggéré, mais beaucoup ne le feraient pas. Les aides à la décision peuvent être utiles pour aider les patients à prendre des décisions cohérentes avec leurs risques, valeurs et préférences personnels."
            },
            {
              Implications_for: 'Cliniciens',
              Strong_recommendation:
                "La plupart des personnes dans cette situation voudraient suivre le plan d'action recommandé, et seule une petite proportion ne le ferait pas.",
              Conditional_recommendation:
                'Reconnaître que différentes options seront appropriées pour les différents patients et que vous devez aider chaque patient à prendre une décision conforme à ses valeurs et à ses préférences.Les aides à la décision peuvent être utiles pour aider les personnes à prendre des décisions cohérentes avec leurs risques, valeurs et préférences personnels.'
            },
            {
              Implications_for: 'Décideurs politiques',
              Strong_recommendation:
                "La recommandation peut être adoptée comme politique dans la plupart des situations. L'adhésion à cette recommandation selon la ligne directrice pourrait être utilisée comme critère de qualité ou indicateur de performance.",
              Conditional_recommendation:
                "L'élaboration des politiques nécessitera des discussions approfondies et la participation de différentes parties prenantes. Les mesures de performance devraient évaluer si la prise de décision est adéquate."
            },
            {
              Implications_for: 'Chercheurs',
              Strong_recommendation:
                "La recommandation est étayée par des recherches crédibles ou d'autres jugements convaincants qui rendent peu probable que des recherches supplémentaires modifient la recommandation. Parfois, une recommandation forte est basée sur une certitude faible ou très faible des données probantes. Dans de tels cas, des recherches supplémentaires peuvent fournir des informations importantes qui modifient les recommandations.",
              Conditional_recommendation:
                "La recommandation est susceptible d'être renforcée (pour de futures mises à jour ou ajustements) par des recherches supplémentaires. Une évaluation des conditions et des critères (ainsi que des analyses connexes, des preuves de recherche et des considérations supplémentaires) qui ont déterminé la recommandation conditionnelle (plutôt que forte) aidera à identifier toute lacune dans la recherche."
            }
          ]
        }
      ],
      glossary_content: {
        point_1: {
          title: 'Recommandation',
          content:
            "Énoncé applicable au choix entre deux ou plusieurs options d'intervention dans une population spécifique et un contexte spécifique, le cas échéant. Les options alternatives [les comparateur(s)] doivent être spécifiées lorsqu'elles ne sont pas évidentes. L'énoncé doit avoir une orientation (pour ou contre une option) et une solidité (par exemple, forte ou conditionnelle) claires. Idéalement, la solidité de la recommandation et la certitude des preuves à l'appui sont énoncées explicitement. Une recommandation doit s'appuyer sur un processus d'élaboration délibératif et structuré. En particulier, elle devrait être étayée par une revue systématique ou une évaluation des technologies de la santé pour les facteurs qui déterminent son orientation et sa solidité.",
          id: '1'
        },
        point_2: {
          title: 'Recommandations de recherche',
          content:
            "Énoncé applicable à l'utilisation des options d'intervention dans une population spécifique, uniquement dans le cadre d'une participation à la recherche. Les recommandations de recherche sont appropriées lorsque trois conditions sont remplies : i) la certitude des preuves disponibles ne permet pas au groupe de développement d'émettre une recommandation formelle ; ii) des recherches supplémentaires apportent un potentiel important de réduire l'incertitude relatives aux effets souhaitables ou indésirables de l'intervention ; iii) et les recherches supplémentaires sont jugées à un prix raisonnable par rapport aux coûts anticipés. Les recommandations de recherche devraient détailler le(s) question(s) de recherche spécifiques que les chercheurs doivent aborder. La recommandation de recherche peut être accompagnée d'une recommandation explicite [forte] de ne pas utiliser l'intervention en dehors du contexte de la recherche.",
          id: '2'
        },
        point_9: {
          title: 'GRADE',
          content:
            "L'approche GRADE (Grading of Recommendations Assessment, Development and Evaluation) est un système d'évaluation de la qualité d'un ensemble de preuves dans des revues systématiques et autres synthèses de preuves, telles que les évaluations des technologies de la santé, les lignes directrices ou les recommandations de classification des soins de santé. GRADE fournit un processus transparent et structuré pour développer et présenter des résumés de preuves et pour effectuer les étapes de développement des recommandations. Il peut être utilisé pour élaborer des directives de pratique clinique (BPC) et d'autres recommandations en matière de soins de santé (par exemple, dans la santé publique, les politiques et les systèmes de santé ainsi que les décisions de couverture).",
          id: '9'
        },
        point_3: {
          title:
            'Catégories GRADE officielles de recommandations/classification',
          text: {
            0: {
              id: '0',
              title: 'Forte recommandation',
              content:
                "Si le panel est très confiant dans l'équilibre entre les effets souhaitables et indésirables, il fait une forte recommandation pour (le désirable l'emporte sur l'indésirable) ou contre (l'indésirable l'emporte sur le souhaitable) une intervention ."

            },
            1: {
              id: '1',
              has_table: 'VERDADERO',
              title: 'Recommandation conditionnelle',
              content:
                "Si le groupe ou le panel d'élaboration des lignes directrices n'est pas certain de l'équilibre entre les effets souhaitables et indésirables pour l'utilisation prévue de la recommandation, il propose une recommandation conditionnelle (également appelée recommandation faible). Cela peut se produire lorsque la certitude des preuves est faible ou très faible, dans le cas où les valeurs et préférences, le coût, l'équité, l'acceptabilité, la faisabilité et d'autres facteurs peuvent dépendre du contexte dans lequel l'intervention ou l'option est mise en œuvre ou sont variables ou incertaines. ."
             },
            2: {
              title: 'Recommandations de recherche',
              content:
                "Énoncé applicable à l'utilisation des options d'intervention dans une population spécifique, uniquement dans le cadre d'une participation à la recherche"
            },

          },
          id: '3'
        },
        point_4: {
          title: 'Recommandations non officielles similaires à GRADE',
          intro:
            "Les catégories suivantes ne sont pas officielles dans l'approche GRADE, elles font partie de classifications alternatives définies par certains groupes d'élaboration de lignes directrices de l'OMS et approuvées par le comité de révision des lignes directrices de l'OMS.",
          text: {
            0: {
              id: '0',
              title: 'Recommandé',
              content:
                "Cette catégorie indique que l'intervention ou l'option devrait être mise en œuvre."
            },
            1: {
              id: '1',
              title: 'Non recommandé ou non recommandé pour la pratique',
              content:
                "Cette catégorie indique que l'intervention ou l'option ne devrait pas être mise en œuvre."
            },
            2: {
              id: '2',
              title: 'Recommandé uniquement dans des contextes spécifiques',
              content:
                "Cette catégorie indique que l'intervention ou l'option s'applique uniquement à la condition médicale, au contexte ou à la population spécifiés dans la recommandation, et ne doit être mise en œuvre que dans ces conditions."
            },
            3: {
              id: '3',
              title: 'Recommander avec un suivi et une évaluation spécifiques',
              content:
                "Cette évaluation indique une incertitude quant à l'efficacité ou l'acceptabilité d'une intervention, en particulier dans la cas de contextes ou conditions médicales particuliers. Les interventions classées comme telles peuvent être envisagées pour une mise en œuvre (même à grande échelle), à ​​condition qu'elles soient accompagnées d'un suivi et d'une évaluation ciblés. Une attention particulière doit être accordée aux problèmes spécifiques qui suscitent des inquiétudes (comme les risques ou les dommages) et pour lesquels peu ou pas d'informations pertinentes sont disponibles. Les informations de suivi et d'évaluation peuvent être obtenues à partir de diverses sources, y compris des données de routine et des données d'enquête. Le comité d'orientation a tenté de clarifier quels aspects des interventions nécessitaient un suivi et a précisé les indicateurs pertinents."
            },
            4: {
              id: '4',
              title:
              'Recommandé uniquement dans le cadre de recherches rigoureuses.',
            content:
              "Cette catégorie indique qu'il existe des incertitudes importantes concernant l'intervention ou l'option. Dans de tels cas, la mise en œuvre peut encore se faire à grande échelle, à condition qu'elle prenne la forme d'une recherche capable de répondre aux questions et incertitudes en suspens liées à l'efficacité de l'intervention ou de l'option, à son acceptabilité et à sa faisabilité."
          },
            5: {
              id: '5',
              title: 'ECM catégorie 1',
              content:
                "Cette catégorie n'est pas officielle dans l'approche GRADE, elle fait partie d'une classification alternative définie par le groupe d'élaboration des lignes directrices et approuvée par le comité de révision des lignes directrices. Cette catégorie indique une condition médicale pour laquelle il n'y a aucune restriction à l'utilisation de la méthode contraceptive."
           },
            6: {
              id: '6',
              title: 'CEM catégorie 2',
              content:
                "Cette catégorie n'est pas officielle dans l'approche GRADE, elle fait partie d'une classification alternative définie par le groupe d'élaboration des lignes directrices et approuvée par le comité de révision des lignes directrices. Cette catégorie indique une condition médicale dans laquelle les avantages de l'utilisation de la méthode contraceptive l'emportent généralement sur les risques théoriques ou avérés."
           },
            7: {
              id: '7',
              title: 'CEM catégorie 3',
              content:
                "Cette catégorie n'est pas officielle dans l'approche GRADE, elle fait partie d'une classification alternative définie par le groupe d'élaboration des lignes directrices et approuvée par le comité de révision des lignes directrices. Cette catégorie indique une condition médicale dans laquelle les risques théoriques ou avérés l'emportent généralement sur les avantages de l'utilisation de la méthode contraceptive."
            },
            8: {
              id: '8',
              title: 'CEM catégorie 4',
              content:
                "Cette catégorie n'est pas officielle dans l'approche GRADE, elle fait partie d'une classification alternative définie par le groupe d'élaboration des lignes directrices et approuvée par le comité de révision des lignes directrices. Cette catégorie indique une condition médicale qui présente un risque inacceptable pour la santé si la méthode contraceptive est utilisée."
            },









          },
          id: '4'
        },
        point_5: {
          title: 'Certitude de la preuve',
          intro:
            "Certitude qu'une estimation d'association ou d'effet est correcte ou, mieux, qu'un véritable effet se situe du côté d'un seuil spécifié ou dans une plage choisie. La certitude des preuves, également connue sous le nom de confiance dans les estimations des effets, la qualité des preuves, la solidité des preuves ou les niveaux de preuves, sont exprimées en quatre catégories : élevée, modérée, faible et très faible, dépendant de l'évaluation des questions des signalement qui suivent pour un ensemble de preuves (entre guillemets, les domaines spécifiques abordés par les scientifiques):",
          text: {
            0: {
              id: '0',
              content:
                "Les études de recherche sont-elles bien faites? \"Limites à la conception détaillée et à l'exécution de l'étude, aussi appelé risque de biais ou validité interne"
            },
            1: {
              id: '1',
              content:
              "Les résultats sont-ils cohérents d'une étude à l'autre ? \"Incohérence"
          },
            2: {
              id: '2',
              content:
              'Dans quelle mesure les résultats sont-ils directement liés à notre question ? "Information indirecte, applicabilité, généralisation, validité externe'
           },
            3: {
              id: '3',
              content:
                "L'ampleur de l'effet est-elle précise - dû à une erreur aléatoire ? \"Imprécision"
             },
            4: {
              id: '4',
              content:
              'Est-ce que ce sont toutes les études qui ont été réalisées? "Biais de publication'
          },
            5: {
              id: '5',
              content:
              'Y a-t-il autre chose qui peut augmenter la certitude? "Effets significatifs, prédicteurs présentant des conclusions encore solides dans les pires cas, gradient dans la relation de cause à effet'
          },

          },
          id: '5'
        },
        point_6: {
          title: "Certitude dans l'estimation de l'effet",
          content:
            "Elle exprime la certitude de l'effet d'une intervention tenant compte de toutes les preuves et s'apparente à la certitude de la preuve.",
          id: '6'
        },
        point_7: {
          title: 'Carte de recommandations',
          content:
            "Un outil pour organiser les recommandations à partir d'un ensemble de lignes directrices sur une condition donnée, rapporter les caractéristiques autour des preuves et des analyses qui informent la recommandation, et mettre en évidence les groupes et les lacunes dans la disponibilité des recommandations dans une représentation numérique schématique et visuelle. Les cartes de recommandations sont organisées selon les composantes de la question PICO (Population, Interventions, Comparateur, Résultat) qui ont guidé l'élaboration de la recommandation concernée.",
          id: '7'
        },
        point_8: {
          title: 'Glossaire du manuel GRADE',
          content:
            'https://gdt.gradepro.org/app/handbook/handbook.html#h.buaodtl66dyx',
          id: '8'
        }
      },
      doc_title: 'BIGG-REC - Recomendaciones GRADE'
    },
    map: {
      recommendations_map: 'Carte des recommandations',
      back_button: 'Retour'
    },
    footer: {
      powered: 'Alimenté par',
      privacy_policy: {

        content_1: 'example {link}',
        link: 'url@gmail',
        main_title: 'Politique de confidentialité'
      },
      contact: {
        name: 'Contact',
        text:"Pour toute question ou demande, veuillez nous contacter à",
        content: {
          title_1: 'Zone réservée'
        }
      },
      terms_and_conditions: {
        text: [
          {
            id: '0',
            content:
              'Content of this site or linked by Epistemonikos Database ("Epistemonikos") to other sites is provided in a free and open way, without contractual link created or existing between the owners and/or administrators of this website and the project\'s participants, and users. Content owned by Epistemonikos that is made available to users can be freely used, subject to the terms and conditions of its licensing scheme. Content from third parties linked to, can be used in accordance with the licensing requirements of the rights owner of the linked content.'
          },
          {
            id: '1',
            content:
              'Only the English version of all the titles and abstracts of available documents, as well as translations marked with a star can be considered reliable. However, regarding all documents and translations, Epistemonikos, its collaborators, administrators, programmers, sponsors, researchers and in general anyone related to Epistemonikos or that works in the project, do not act as guarantors nor are in any way obligated or liable regarding the exactitude, completeness, or use of the provided information, which is owned by their corresponding rights holders.'
          },
          {
            id: '2',
            content:
              'Epistemonikos does not support, endorse or recommend any commercial product, treatment or service. The points of view and opinions of individual authors of the information that is made available do not necessarily reflect the opinion of Epistemonikos, nor of its collaborators, administrators, programmers, sponsors, researchers, and in general of any person related to Epistemonikos or that works in the project. Therefore, they cannot be used for commercial purposes or in order to support a specific product, treatment or service.'
          },
          {
            id: '3',
            content:
              'Epistemonikos does not provide specific health advice, consulting or recommendations but instead seeks to provide to the general public, in a systematized form, the best and most reliable available information so as to better understand the problems of healthcare. Epistemonikos does not assume any legal obligation or liability derived from the application of the information to real situations. The information made available to the public by Epistemonikos, especially about pharmacotherapy and surgical procedures, does not in any way substitute, replace or complement advice provided by doctors or other healthcare professionals. Any use of the information contained in Epistemonikos is the sole responsibility of the user.'
          },
          {
            id: '4',
            content:
              'Epistemonikos.org is a collaborative nonprofit project maintained by Epistemonikos Foundation. All of its content and features are available for free.'
          }
        ],
        intellectual_property: {
          text: [
            {
              id: '0',
              content:
                'Epistemonikos links to contents located in other websites and databases, mainly serialized scientific journals. Said links provide access to content owned by third parties, when allowed by them. Content located in linked sites are in no way amended or modified. Copyright of the content to which Epistemonikos links is owned by their respective rights holders.'
            },
            {
              id: '1',
              content:
                'For better reference, and for purposes of research and academia, Epistemonikos can provide fragments of the linked content, including a direct reference to the source, title and author.'
            },
            {
              id: '2',
              content:
                'Any trademark, whether for products, services, composite marks, collective marks, industrial designs, patents or other intellectual property rights than can be addressed, commented on or cited in Epistemonikos’ content, or content linked to, are owned by their respective rights holders. Unless expressly stated, Epistemonikos has no relation nor contractual link to the corresponding rights holders.'
            }
          ],
          laws: [
            {
              text: [
                {
                  id: '0',
                  content:
                    'Epistemonikos is a collaborative nonprofit project that strives to publicize and ease access to evidence-based medicine knowledge. Linked information is used in compliance with the laws and international treaties in force in the Republic of Chile.'
                },
                {
                  id: '1',
                  content:
                    'If you own the copyright of a work contained or referred to in the website, and believe the Epistemonikos’ use of it is infringing, please contact contact@epistemonikos.org, stating your rights to the work, its location in the website, the rights allegedly infringed and, if applicable, the user that provides the infringing content. If justified, in compliance with Law 17.336 of Intellectual Property, the infringing content will be removed.'
                }
              ],
              name: 'Law 17.336 of Intellectual Property (Chile)'
            }
          ],
          title: 'Intellectual Property'
        },
        jurisdiction: {
          text: [
            {
              id: '0',
              content:
                'Epistemonikos publishes, links to or makes available to users, all its content in compliance with the laws of the Republic of Chile. Any controversy, conflict or claim arising from the website and its content, will be decided in a Chilean court of law, in compliance with applicable laws in force in the Republic of Chile.'
            }
          ],
          title: 'Jurisdiction'
        },
        main_title: 'Termes et conditions'
      }
    },
    modals: {
      contact: {
        title: 'Contact',
        content:
          'Pour toute question ou demande relative à cette politique de confidentialité, veuillez nous contacter à bir.online@paho.org'
      }
    },
    undefined: 'en'
  },
  pt: {
    general: {
      doc_title: 'BIGG-REC - Recomendações GRADE',
      home: 'Home',
      map: 'Mapa',
      year: 'Ano',
      clear: 'Limpar',
      institutions: {
        WHO: 'Organização Mundial da Saúde',
        PAHO: 'Organização Pan-Americana da Saúde'
      },
      recommendation: 'Recomendação | Recomendações',
      guideline: 'Diretiva | Diretrizes',
      direction: 'Orientação',
      rec_strength: 'Força da recomendação',
      rec_type_labels: {
        strong: 'Forte',
        conditional: 'Condicional',
        recommended: 'Recomendado',
        not_recommended: 'Não recomendado',
        research_context: 'Recomendado no contexto de investigações rigorosas',
        context_specific: 'Recomendado em contextos específicos',
        targeted_monitoring_context: 'Recomendado em contexto de supervisão',
        mec_1: 'MEC 1',
        mec_2: 'MEC 2',
        mec_3: 'MEC 3',
        mec_4: 'MEC 4'
      },
      rec_type_tooltips: {
        strong:
          'Uma recomendação pode ser forte ou condicional. Quando uma recomendação é forte, todos as pessoas desejam seguir a recomendação sugerida. Quando uma recomendação é condicional, a maioria das pessoas deseja segui-la, mas não antes de uma conversa com o seu profissional de saúde.',
        conditional:
          'Uma recomendação pode ser forte ou condicional. Quando uma recomendação é forte, todos as pessoas desejam seguir a recomendação sugerida. Quando uma recomendação é condicional, a maioria das pessoas deseja segui-la, mas não antes de uma conversa com o seu profissional de saúde.',
        recommended:
          'Esta categoria indica que a intervenção ou opção deve ser implementada. Esta categoria não é oficial na abordagem GRADE, faz parte de uma classificação alternativa definida pelo grupo de desenvolvimento de diretrizes e aprovada pelo comitê de revisão de diretrizes.',
        not_recommended:
          'Esta categoria indica que a intervenção ou opção não deve ser implementada. Esta categoria não é oficial na abordagem GRADE, faz parte de uma classificação alternativa definida pelo grupo de desenvolvimento de diretrizes e aprovada pelo comitê de revisão de diretrizes.',
        context_specific_recommendation:
          'Esta categoria indica que a intervenção ou opção é aplicável apenas à condição, ambiente ou população especificada na recomendação e só deve ser implementada nesses contextos. Esta categoria não é oficial na abordagem GRADE, faz parte de uma classificação alternativa definida pelo grupo de desenvolvimento de diretrizes e aprovada pelo comitê de revisão de diretrizes.',
        research_context_recommendation:
          'Esta categoria indica que há incerteza significativa sobre a intervenção ou opção. Nesses casos, a implementação ainda pode ser realizada em larga escala, desde que seja realizada na forma de pesquisa capaz de abordar questões não respondidas e incertezas relacionadas tanto à eficácia da intervenção quanto a sua aceitabilidade e viabilidade. Esta categoria não é oficial na abordagem GRADE, faz parte de uma classificação alternativa definida pelo grupo de desenvolvimento de diretrizes e aprovada pelo comitê de revisão de diretrizes.',
        recommended_in_the_context_of_targeted_monitoring_and_evaluation:
          'Esta classificação indica incerteza sobre a eficácia ou aceitabilidade de uma intervenção, especialmente no que diz respeito a contextos ou condições particulares. Esta categoria não é oficial na abordagem GRADE, faz parte de uma classificação alternativa definida pelo grupo de desenvolvimento de diretrizes e aprovada pelo comitê de revisão de diretrizes.',
        mec_category_1:
          'Esta categoria não é oficial na abordagem GRADE, faz parte de uma classificação alternativa definida pelo grupo de desenvolvimento de diretrizes e aprovada pelo comitê de revisão de diretrizes. Esta categoria indica uma condição para a qual não há restrição ao uso do método contraceptivo.',
        mec_category_2:
          'Esta categoria não é oficial na abordagem GRADE, faz parte de uma classificação alternativa definida pelo grupo de desenvolvimento de diretrizes e aprovada pelo comitê de revisão de diretrizes. Esta categoria indica uma condição em que os benefícios do uso do método contraceptivo geralmente superam os riscos teóricos ou comprovados.',
        mec_category_3:
          'Esta categoria não é oficial na abordagem GRADE, faz parte de uma classificação alternativa definida pelo grupo de desenvolvimento de diretrizes e aprovada pelo comitê de revisão de diretrizes. Esta categoria indica uma condição em que os riscos teóricos ou comprovados muitas vezes superam os benefícios do uso do método contraceptivo.',
        mec_category_4:
          'Esta categoria não é oficial na abordagem GRADE, faz parte de uma classificação alternativa definida pelo grupo de desenvolvimento de diretrizes e aprovada pelo comitê de revisão de diretrizes. Esta categoria indica uma condição que representa um risco inaceitável para a saúde se o método contraceptivo for usado.'
      },
      type: 'Tipo',
      coe: 'Força da evidência',
      coe_tooltip:
        'A força da evidência é o julgamento sobre até que ponto podemos ter certeza de que as estimativas de efeito estão corretas.',
      coe_labels: {
        high: 'Alta',
        moderate: 'Moderada',
        low: 'Baixa',
        'very low': 'Muito Baixa'
      },
      notes: 'Anotações e comentários',
      population: 'População | Populações',
      intervention: 'Intervenção | intervenções',
      sdg3_goals: 'Meta ODS3 | Metas ODS3',
      sdg3_header_1: 'Navegar por meta',
      sdg3_header_2: 'Objetivo:',
      sdg3_targets: {
        '6167009e06768adee26a276f': 'Saúde materna',
        '616700b43552581d99259cbd': 'Saúde da criança',
        '616700c83d0515512662039e': 'Doenças transmissíveis',
        '616700dfea88905b6d651ab4': 'Doenças não transmissíveis',
        '6167019d06768adee26a27b6': 'Consumo de substâncias psicoativas',
        '616701b83d051551266203e3': 'Saúde sexual e reprodutiva',
        '616701f0ea88905b6d651b03': 'Cobertura Universal de Saúde',
        '616701fd06768adee26a27d3': 'Poluição do ar, da água e do solo',
        '6167020b3d05155126620408': 'Pessoal de saúde',
        '6167021bea88905b6d651b12': 'Riscos nacionais e globais de saúde',
        '6167022606768adee26a27e8': 'Acidentes de trânsito',
        '616702393d05155126620415': 'Controle do uso do tabaco',
        '61670245ea88905b6d651b27': 'Desenvolvimento de vacinas e medicamentos',
        '6232d8b806768a45cafb964b': 'Aleitamento materno',
        '5d25e658daeedf1d3af3331b': 'Abandono do Hábito de Fumar',
        '623302a1355258bf19d48919': 'Políticas de controle do tabaco',
        '623d860d355258962733d076': 'Intervenções WASH',
        '5d31a969daeedf1d3af3333f': 'Provisão e distribuição',
        '61e093773d0515f69ae5f25f': 'Serviços de aborto seguro',
        '5dd7a70469c00e7ae9856041': 'Acordos financeiros',
        '5dd7eb313c12ef61034d00f3': 'Arranjos de governança',
        '5d4c5d0769c00e0d5edaea4b': 'Técnicas anestésicas',
        '61969b2aea88903ea3a9cb6b': 'Cuidado pré-natal',
        '619d5dc2307372254d2fb70c': 'Planejamento familiar',
        '622630fb355258e04d57f2b5': 'Maconha',
        '62263163ea8890a65d455607': 'Álcool',
        '622633c5307372a476759d26': 'Opioides',
        '62263906355258e04d57f50e': 'Hipnóticos e sedativos',
        '62263c23ea8890b83445534b': 'Cocaína',
        '62263cdb06768a27badd846a': 'Anfetaminas',
        '6226424eea8890cac54552db': 'Alucinógenos',
        '62264853307372b78b75a001': 'Inalantes',
        '6232f74d3552589cf3d489df': 'Exposição ocupacional ou ambiental',
        '62af88f506768ad40b76ca75': 'Exposição a substâncias em crianças',
        '61e02fc03552586c64987855': 'Mordida de cobra',
        '61e0927e06768a05b24df5ea': 'Acidentes de trânsito',
        '6230e95469c00e8b53a62277': 'Pedestres',
        '6230ea4d69c00e8b53a622be': 'Motoristas de veículos automotores',
        '6230f589355258f491a91beb': 'Ciclistas',
        '6230f744ea889066d8cf39a5': 'Vítimas de acidentes de trânsito',
        '5e18816a19a11a14c75ed9a1': 'Cefaleia',
        '5e18816a19a11a14c75ed9a2': 'Enxaqueca',
        '5d7e803069c00e72a5188e4f': 'Aborto espontâneo, natimorto e aborto',
        '61ae22493d05159df98a787c': 'Complicações do trabalho de parto',
        '61ae228e06768afaf4086a69': 'Ruptura uterina',
        '61ae329e3d0515bfa88a791a': 'Hemorragia uterina',
        '61ae3b98ea88906f40808bde': 'Ruptura prematura de membranas fetais',
        '61df380b307372c1b76fddfa': 'Trabalho de parto prolongado',
        '61df3c89307372c1b76fdf53': 'Parto instrumental',
        '61df3dc73d0515f181e5f312': 'Cesárea',
        '61df40563552585a4198768a': 'Parto induzido',
        '61df4193307372c1b76fe0b4': 'Lesão perineal',
        '61df4312307372d5566fdd1a': 'Trauma obstétrico',
        '61df4fab3552589b2c98759a': 'Transtornos hipertensivos na gravidez',
        '61ae37433552583a066132f2': 'Infecções na gravidez',
        '61ae9e133d0515be1f8a7b58':
          'Recém-nascido grande para a idade gestacional',
        '61df49fb3d05152368e5f105': 'Tromboembolismo na gravidez',
        '61ae05fd355258b08e6134d0': 'Gravidez ectópica',
        '60eefcdd3d0515c51d3f02ae': 'Gravidez na adolescência',
        '5d062d5fc80dd41e58ba8459': 'Gestantes',
        '6269b62a3552584246eb4bb5': 'Diabetes gestacional',
        '5cf20ea0c80dd41e58ba8259': 'Hepatite viral',
        '5d0a8a04c80dd41e57ba89a0': 'Raiva',
        '5d0a8a85dbe4d25bcfbee78f': 'Febre tifoide e paratifoide',
        '61e005a2307372bffc6fdf9b': 'Diarreia infecciosa',
        '5d0a9c60dbe4d25bcfbee797': 'Tuberculose',
        '5d0b446adbe4d25bcfbee7fd': 'Septicemia',
        '5d4a7cd869c00e69c91e5ff8': 'Meningite aguda',
        '5d0b4ec7dbe4d25bcfbee800': 'Dengue',
        '5d52927869c00e3de733d820': 'HIV',
        '5d52d6bf69c00e3de733d835': 'Pneumonia',
        '5d6bcb6369c00e39658ba0a2': 'Malária',
        '5d7f530269c00e72a5188e5e': 'Doença de Chagas',
        '5da2f85069c00e78ea7056d5': 'Brucelose',
        '5da2fbf63c12ef5722a1d717': 'Infecção por Zika virus',
        '61e01ad006768a739d4df424': 'Chicungunha',
        '63c30f7dc96e6d00081e1b35': 'Infecção por Taenia solium',
        '5e7fce7e3d05156b5f5e032a': 'COVID-19',
        '618524ca307372a53541f22e': 'Emergências infecciosas de Saúde Pública',
        '628667843552584bba67f67a': 'Varíola dos macacos',
        '61967a183d051589e00a0104': 'Febre hemorrágica da Crimeia',
        '619697e869c00e50e228c6c1': 'Peste',
        '61df52ea69c00e31667ed778': 'Antraz',
        '61df53973552589d7298765a': 'Ascaridíase',
        '61e00914ea8890a3bcfe632e': 'Tifo epidêmico',
        '61e075323552580bfd9877ea': 'Tifo por ácaro',
        '61e00ae869c00ecf047ed7a1': 'Gnatostomíase',
        '61e00c83307372d43a6fdda8': 'Infestações por Piolhos',
        '61e0147f3552582e469878a6': 'Histoplasmose',
        '61e01531307372d43a6fdfe9': 'Encefalite japonesa',
        '61e016663552584ac49875ba': 'Leishmaniose',
        '61e0174dea8890b596fe633c': 'Sarampo',
        '61e01812307372e5dc6fdd04': 'Melioidose',
        '61e0191d3d0515e90ae5f1bf': 'Doenças tropicais negligenciadas',
        '61e0773706768ae17b4df63b': 'Estrongiloidíase',
        '61e02d0169c00e0b937ed7e6': 'Escabiose',
        '61e073d2ea88903585fe6172': 'Paracoccidioidomicose',
        '61e076a3ea88903585fe6231': 'Varíola',
        '61e077fa06768ae17b4df66a': 'Encefalite transmitida por carrapatos',
        '61e0788e30737252756fe032': 'Tricomoníase',
        '5cfa863adbe4d25bcfbee2c7': 'Câncer',
        '62a904023d0515e3d541fcc3': 'Câncer infantil',
        '5d0b5bdedbe4d25bcfbee803': 'DPOC',
        '5d7520f369c00e39658ba0fa': 'Asma',
        '62a91523ea88903c2895b27b': 'Doenças respiratórias na infância',
        '5d14114adaeedf730426b7b8': 'Doenças do tecido conjuntivo',
        '5d234900daeedf1d3af33306': 'Doenças dentárias',
        '5d27332adaeedf1d3bf3330a': 'Doenças hematológicas',
        '5da2ea543c12ef5722a1d710': 'Anemia falciforme',
        '61e08aee307372777b6fdd98': 'Talassemia',
        '5d2756d7daeedf1d3af3332a': 'Doença isquêmica do coração',
        '5d3f38f669c00e04ee74f5e7': 'Tromboembolismo venoso',
        '5d3f397e69c00e04ee74f5e9': 'Transtornos cerebrovasculares',
        '5d3f3aec69c00e04ef74f5c8': 'Hipertensão',
        '5d7e2ac769c00e72a5188e41': 'Epilepsia',
        '5d7e2be069c00e72a5188e42': 'Esclerose múltipla',
        '5d99f1f03c12ef11577a0053': 'Doença de Parkinson',
        '62a90e9eea88903c2895b0d2': 'Manifestações neurológicas na infância',
        '5d4322c869c00e198a009df3': 'Saúde mental',
        '5d4322f769c00e198b009e1b': 'Demência',
        '5d43245e69c00e198b009e1e':
          'Transtornos relacionados ao uso de substâncias',
        '5da097e33c12ef4de3dce666': 'Violência doméstica',
        '5da097e33c12ef4de3dce667': 'Abuso sexual',
        '5da097e33c12ef4de3dce668': 'Maus-tratos infantis',
        '62a90f8e06768aeab576cc5d': 'Saúde mental infantil',
        '5d44e01769c00e198b009e39': 'Transtornos gastrointestinais',
        '5d52974169c00e3de733d823': 'Cirrose hepática',
        '5d5832c369c00e490b8f5553': 'Oftalmopatias',
        '5d5e8e3669c00e507e1c8883': 'Insuficiência renal crônica',
        '5d9680db69c00e73b4b22508': 'Infertilidade',
        '5d7e231569c00e72a5188e3c': 'Otorrinolaringopatias',
        '5d7f88af69c00e72a3188e15': 'Doenças congênitas',
        '5d94f1d13c12ef641517c8d6': 'Doenças endócrinas',
        '5d52919a69c00e3de733d81f': 'Diabetes',
        '5d94f2a13c12ef641517c8d7': 'Doenças nutricionais e metabólicas',
        '5cf00956dbe4d25bcfbedf17': 'Sobrepeso e obesidade',
        '62a902c6307372b0385ca5db': 'Obesidade infantil',
        '6231aeb73073724b9bd88b7d': 'Doenças infantis',
        '6231af3d06768abaa9e445b2': 'Saúde do recém-nascido',
        '6232ebdbea8890f8d8bbde6d': 'Recém-Nascido Prematuro de baixo peso',
        '6232f051307372ecd7349e7c': 'Asfixia neonatal',
        '61df363006768a5c4c4df45a': 'Monitorização do parto',
        '60c8b64006768aab069c540d': 'Resistência microbiana a medicamentos',
        '60ef101206768ac0e77738d8': 'Infecções sexualmente transmissíveis',
        '5d0b46cec80dd41e57ba89c1': 'Sífilis',
        '5d7e31f669c00e72a5188e45': 'Traumas, intoxicações e lesões',
        '6232f489307372009d349b86': 'Intoxicação acidental',
        '61e0866106768af3424df682': 'Estilo de vida sedentário',
        '61e08a2569c00ef2ea7ed7d2': 'Ingestão de sal',
        '6208400dea88909b3f0a3c97': 'Contaminação da água',
        '620840bb355258cf6dfd0138': 'Qualidade do ar',
        '620840e306768aef5a232488': 'Contaminação do solo',
        '623370453d0515a24b10fd28': 'Pessoal de saúde',
        '623382d0307372b09a349e1e': 'Pessoal técnico de saúde',
        '6233909a69c00ed278da2098': 'Agentes Comunitários de Saúde',
        '6233924406768a3c75fb97a3': 'Pessoal de apoio à gestão da saúde',
        '619a453469c00e98db28c887': 'Desastres climáticos',
        '623450bb307372f556349d92': 'Desastres provocados pelo homem',
        '62345349ea8890143ebbdcab': 'Desastres sísmicos',
        '630fdb8dc7585e000861eb74': 'Dor do parto',
        '630fd98ac7585e000861eaec': 'Bacteriúria assintomática em gestantes',
        '5d6848cb69c00e131b5a4cc0': 'Trabalho e entrega',
        '630fd43db25ac300085beafb': 'Período pós-parto',
        '630fd787b25ac300085beba6': 'Desnutrição materna',
        '630fddefb128010008f0ed9f': 'procedimentos obstétricos',
        other_populations: 'Outras populações'
      },
      sdg3_populations: {
        '5cec39fcdbe4d24b26f43daf': 'Dermatopatias',
        '5cf20e7bc80dd41e57ba82b8': 'Doenças infecciosas',
        '5cfa863adbe4d25bcfbee2c7': 'Câncer',
        '5d0b5a7bdbe4d25bcebee2a3': 'Doenças respiratórias',
        '5d14114adaeedf730426b7b8': 'Doenças do tecido conjuntivo',
        '5d234900daeedf1d3af33306': 'Doenças dentárias',
        '5d27332adaeedf1d3bf3330a': 'Condições hematológicas',
        '5d27427edaeedf1d3af33328': 'Doenças cardiovasculares',
        '5d3f3da269c00e04ee74f5ea': 'Doenças hematológicas',
        '5d4322c869c00e198a009df3': 'Saúde mental',
        '5d44e01769c00e198b009e39': 'Transtornos gastrointestinais',
        '5d5832c369c00e490b8f5553': 'Oftalmopatias',
        '5d5e8d9d69c00e507e1c8882': 'Doenças urogenitais',
        '5d7e231569c00e72a5188e3c': 'Otorrinolaringopatias',
        '5d7f88af69c00e72a3188e15': 'Doenças congênitas',
        '5d94f1d13c12ef641517c8d6': 'Doenças endócrinas',
        '5d94f2a13c12ef641517c8d7': 'Doenças nutricionais e metabólicas',
        '5d7d93b769c00e72a5188e34':
          'Obstetrícia, Ginecologia e Saúde da Mulher',
        '61ae329e3d0515bfa88a791a': 'Sangramento uterino'
      },
      sdg3_interventions: {
        '5cec5dfdc80dd40a6f61aea0': 'Tratamento farmacológico',
        '5d1b60b4daeedf74ca4f4b51': 'Atividade física e fisioterapia',
        '5d247420daeedf1d3af3330e':
          'Medicina complementar, alternativa e integrativa',
        '5d25202adaeedf1d3af33311': 'Procedimentos',
        '5d2523a9daeedf1d3af33312': 'Dietoterapia',
        '5d252f01daeedf1d3af33314': 'Intervenções comportamentais',
        '5d25e34fdaeedf1d3af33315': 'Saúde pública',
        '5d25e37cdaeedf1d3af33316': 'Sistemas de saúde',
        other_interventions: 'Outras intervenções'
      },
      history: 'Registro',
      filters: {
        show_filters: 'Mostrar filtros',
        hide_filters: 'Ocultar filtros',
        active_filters: '({ativo} ativo)',
        name: 'filtros',
        year: {
          name: 'Ano',
          placeholder: 'Todos',
          options: {
            last_year: lastYear(),
            last_two: `De ${lastTwoYears()}`,
            last_five: `De ${lastfiveYears()}`
          }
        },
        age: {
          name: 'De',
          placeholder: 'Todos',
          options: {
            children: 'Crianças',
            adolescents: 'Adolescentes',
            adults: 'Adultos'
          }
        },
        institution: {
          name: 'Instituição',
          placeholder: 'Todos',
          options: {
            who: 'Organização Mundial da Saúde',
            paho: 'Organização Pan-Americana da Saúde'
          }
        }
      },
      loading: 'Carregando',
      see_episte: 'Veja em Epistemonikos',
      see_love: 'Veja em L.OVE',
      see_map: 'Ver mapa',
      map_button:
        'Selecione uma das 13 metas do ODS3 para ver o mapa de recomendações',
      toggle_guidelines: 'Mostrar título da diretriz',
      view_guideline: 'Mostrar diretriz',
      pending_translation: 'Tradução pendente',
      total_recs: 'Total: {total} recomendações',
      platform_title: 'Recomendações GRADE da OPAS/OMS para ODS-3',
      show_more: 'Mais detalhes',
      fulltext: 'Texto completo',
      search_by_keyword: 'Pesquisar na BIGG-REC'
    },
    pico_builder: {
      no_hits: 'Não foram encontrados resultados',
      sdg3_list_title: 'Selecione a meta do ODS3',
      treatment_list_title: 'Selecione a intervenção',
      population_list_title: 'Selecione a população',
      show_more: 'Ver mais',
      search: 'Termo de busca'
    },
    recommendation: {
      history_message: 'Histórico de recomendações',
      superseded: 'Substituída',
      superseded_tooltip:
        'Esta recomendação foi substituída e não está vigente',
      thread_message:
        'Esta recomendação também está disponível nas seguintes diretrizes:',
      newer_versions:
        'Esta recomendação foi substituída por nova versão mais atual',
      older_versions: 'Esta recomendação substitui as seguintes:',
      appears_in: 'Esta recomendação está nas seguintes diretrizes clínicas:'
    },
    guideline: {
      focus: 'Foco',
      focus_labels: {
        public_health: 'Saúde pública',
        clinical_practice: 'Prática clínica',
        health_policy_systems: 'Políticas e sistemas de saúde'
      },
      users: 'usuários',
      users_labels: {
        healthcare_workers: 'Pessoal de saúde',
        public_health_authorities: 'Autoridades de saúde pública',
        health_facility_administrators: 'Administradores de centros de saúde',
        occupational_health_authorities: 'Autoridades de saúde ocupacional'
      },
      organization_type: 'Tipo de organização',
      organization_types: {
        intergov_not_profit: 'Intergovernamental, sem fins lucrativos',
        gov_not_profit: 'Governo, sem fins lucrativos',
        for_profit: 'Com fins lucrativos'
      },
      recs_included: 'Recomendações incluídas nesta diretriz:',
      ext_links: 'Links externos:',
      institution: 'Instituição',
      links: 'links'
    },
    top_menu: {
      home: 'Página inicial',
      terms:
        'Termos e condições de uso dos sites e sistemas da BIREME/OPAS/OMS',
      about: 'Sobre',
      disclaimer: 'Isenção de responsabilidade',
      disclaimer_content:
        'Não somos responsáveis ​​pela precisão ou integridade do conteúdo deste site. Embora tenham sido envidados esforços e cuidados razoáveis ​​na sua preparação, não assumimos qualquer responsabilidade por erros ou omissões, podendo ser necessário consultar a fonte original da informação. As recomendações podem ser atualizadas ou alteradas à medida que as evidências de pesquisa e a literatura de diretrizes se acumulam. As informações fornecidas neste site não substituem o julgamento profissional.',
      glossary: 'Glossário',
      glossary_tables: [
        {
          title: 'Interpretação de recomendações fortes e condicionais', fields:[
            {key:"Implications_for",label:"Implicações para"},
            {key:"Strong_recommendation",label:"Recomendação forte"},
            {key:"Conditional_recommendation",label:"Recomendação Condicional"},
          ],
          content: [
            {
              Implications_for: 'pacientes',
              Strong_recommendation:
                'A maioria dos indivíduos nessa situação desejaria o curso de ação recomendado e apenas uma pequena proporção não o faria.',
              Conditional_recommendation:
                'A maioria das pessoas gostaria do curso de ação sugerido, mas muitos não. Estruturas de tomada de decisão compartilhada podem ser úteis para ajudar os pacientes a tomar decisões consistentes com seus riscos, valores e preferências individuais.'
            },
            {
              Implications_for: 'clínicos',
              Strong_recommendation:
                'A maioria dos indivíduos nessa situação desejaria o curso de ação recomendado e apenas uma pequena proporção não o faria.',
              Conditional_recommendation:
                'Reconhecer que diferentes opções seriam apropriadas para diferentes pacientes, e que cada paciente deve ser auxiliado para chegar a uma decisão de manejo consistente com seus valores e preferências. e preferências.'
            },
            {
              Implications_for: 'Desenvolvedores de políticas',
              Strong_recommendation:
                'A recomendação pode ser adotada como política na maioria das situações. O cumprimento desta recomendação de acordo com a diretriz pode ser usado como um indicador de qualidade ou indicador de desempenho.',
              Conditional_recommendation:
                'A formulação de políticas exigirá um debate significativo e a participação de muitas partes interessadas.Os indicadores de desempenho precisariam se concentrar no fato de que ocorreu uma deliberação adequada sobre as opções de gestão.'
            },
            {
              Implications_for: 'pesquisadores',
              Strong_recommendation:
                'A recomendação é apoiada por pesquisas confiáveis ​​ou outros julgamentos convincentes que tornam improvável que pesquisas futuras alterem a recomendação. Às vezes, uma recomendação forte é baseada na certeza baixa ou muito baixa da evidência. Nesses casos, pesquisas adicionais podem fornecer informações importantes que alteram as recomendações.',
              Conditional_recommendation:
                'É provável que a recomendação seja fortalecida (para futuras atualizações ou adaptações) por mais pesquisas. Uma avaliação das condições e critérios (e julgamentos relacionados, evidências de pesquisa e considerações adicionais) que determinaram a recomendação condicional (em vez de forte) ajudará a identificar possíveis lacunas de pesquisa.'
            }
          ]
        }
      ],
      glossary_content: {
        point_1: {
          title: 'Recomendação',
          content:
            'Premissa acionável sobre a escolha entre duas ou mais opções de intervenção em uma população específica e em um ambiente específico, quando relevante. Opções alternativas [comparadores] devem ser especificadas quando não forem óbvias. A declaração deve ter uma direção clara (a favor ou contra uma opção) e força (por exemplo, forte ou condicional). Idealmente, a força da recomendação e a certeza da evidência de apoio são declaradas explicitamente. Uma recomendação deve ser apoiada por um processo de desenvolvimento deliberativo e estruturado. Em particular, deve ser apoiado por uma revisão sistemática ou avaliação da tecnologia de saúde dos fatores que determinam sua direção e força.',
          id: '1'
        },
        point_2: {
          title: 'Recomendações de pesquisa',
          content:
            'Premissas acionáveis ​​sobre o uso de opções ou intervenções em uma população específica, apenas no contexto da pesquisa. Recomendações de pesquisa são apropriadas quando três condições são atendidas: i) a certeza da evidência disponível não permite que o grupo de diretrizes emita uma recomendação formal; ii) pesquisas adicionais têm grande potencial para reduzir a incerteza sobre as consequências desejáveis ​​ou indesejáveis ​​da intervenção; iii) e pesquisas adicionais são consideradas de bom valor para os custos previstos. As recomendações de pesquisa devem ser detalhadas com relação às questões de pesquisa específicas que os investigadores precisam abordar. A recomendação para pesquisa pode ser acompanhada por uma recomendação explícita [forte] para não usar a intervenção fora do contexto da pesquisa.',
          id: '2'
        },
        point_9: {
          title: 'GRADE',
          content:
            'A abordagem GRADE é um sistema para avaliar a qualidade do corpo de evidências em revisões sistemáticas e outras sínteses de evidências, como avaliações e diretrizes de tecnologias de saúde e formulação de recomendações de saúde. A abordagem GRADE (Classificação de Avaliação, Desenvolvimento e Avaliação de Recomendações) fornece um processo transparente e estruturado para desenvolver e apresentar resumos de evidências e para executar as etapas envolvidas na formulação de recomendações. Pode ser usado para desenvolver diretrizes de prática clínica (CPG) e outras recomendações da área de saúde (por exemplo, saúde pública, sistemas e políticas de saúde pública e decisões de cobertura de saúde).',
          id: '9'
        },
        point_3: {
          title: 'Categorias oficiais de recomendações/classificação do GRADE.',
          text: {
            0: {
              id: '0',
              title: 'Recomendação forte ou firme',
              content:
                'Se o painel estiver altamente confiante no equilíbrio entre consequências desejáveis ​​e indesejáveis, ele fará uma forte recomendação a favor (o desejável supera o indesejável) ou contra (o indesejável supera o desejável) uma intervenção.'

            },
            1: {
              id: '1',
              has_table: 'VERDADERO',
              title: 'Recomendação forte ou firme',
              content:
                'Se o painel estiver altamente confiante no equilíbrio entre consequências desejáveis ​​e indesejáveis, ele fará uma forte recomendação a favor (o desejável supera o indesejável) ou contra (o indesejável supera o desejável) uma intervenção.'
             },
            2: {
              id: '2',
              title: 'Recomendações de pesquisa',
              content:
                'Premissa acionável sobre o uso de opções ou intervenções em uma população específica, apenas no âmbito da participação em pesquisa'
           },

          },
          id: '3'
        },
        point_4: {
          title: 'Tipo de recomendações GRADE não oficiais',
          intro:
            'As seguintes categorias não são oficiais na abordagem GRADE, fazem parte de classificações alternativas definidas por alguns grupos de desenvolvimento de diretrizes da OMS e aprovadas pelo comitê de revisão de diretrizes da OMS.',
          text: {
            0: {
              id: '0',
              title: 'Recomendado',
              content:
                'Esta categoria indica que a intervenção ou opção deve ser implementada.'
            },
            1: {
              id: '1',
              title: 'Não recomendado ou recomendado contra',
              content:
                'Esta categoria indica que a intervenção ou opção não deve ser implementada.'
            },
            2: {
              id: '2',
              title: 'Recomendado apenas em contextos específicos',
              content:
                'Esta categoria indica que a intervenção ou opção é aplicável apenas à condição, ambiente ou população especificada na recomendação e só deve ser implementada nesses contextos.'
            },
            3: {
              id: '3',
              title: 'Recomendado com monitoramento e avaliação específicos',
              content:
                'Esta classificação indica incerteza sobre a eficácia ou aceitabilidade de uma intervenção, especialmente no que diz respeito a contextos ou condições particulares. As intervenções assim classificadas podem ser consideradas para implementação (mesmo em larga escala), desde que acompanhadas de monitoramento e avaliação específicos. Atenção especial deve ser dada a questões específicas sobre as quais existem preocupações (como riscos ou danos) e sobre as quais pouca ou nenhuma informação relevante está disponível. As informações de monitoramento e avaliação podem ser obtidas de uma variedade de fontes, incluindo dados de rotina e dados de pesquisa. O grupo de desenvolvimento de diretrizes tentou especificar quais aspectos das intervenções exigiam monitoramento e especificou os indicadores relevantes.'
           },
            4: {
              id: '4',
              title: 'Recomendado apenas no contexto de pesquisa rigorosa',
              content:
                'Esta categoria indica que há incerteza significativa sobre a intervenção ou opção. Nesses casos, a implementação ainda pode ser realizada em larga escala, desde que seja realizada na forma de pesquisa capaz de abordar questões não respondidas e incertezas relacionadas tanto à eficácia da intervenção ou opção quanto à sua aceitabilidade e viabilidade.'
             },
            5: {
              id: '5',
              title: 'MEC Categoria 1',
              content:
                'Esta categoria não é oficial na abordagem GRADE, faz parte de uma classificação alternativa definida pelo grupo de desenvolvimento de diretrizes e aprovada pelo comitê de revisão de diretrizes. Esta categoria indica uma condição para a qual não há restrição ao uso do método contraceptivo.'
            },
            6: {
              id: '6',
              title: 'MEC categoria 2',
              content:
                'Esta categoria não é oficial na abordagem GRADE, faz parte de uma classificação alternativa definida pelo grupo de desenvolvimento de diretrizes e aprovada pelo comitê de revisão de diretrizes. Esta categoria indica uma condição em que os benefícios do uso do método contraceptivo geralmente superam os riscos teóricos ou comprovados.'
            },
            7: {
              id: '7',
              title: 'MEC categoria 3',
              content:
                'Esta categoria não é oficial na abordagem GRADE, faz parte de uma classificação alternativa definida pelo grupo de desenvolvimento de diretrizes e aprovada pelo comitê de revisão de diretrizes. Esta categoria indica uma condição em que os riscos teóricos ou comprovados muitas vezes superam os benefícios do uso do método contraceptivo.'
             },
            8: {
              id: '8',
              title: 'MEC categoria 4',
              content:
                'Esta categoria não é oficial na abordagem GRADE, faz parte de uma classificação alternativa definida pelo grupo de desenvolvimento de diretrizes e aprovada pelo comitê de revisão de diretrizes. Esta categoria indica uma condição que representa um problema de saúde inaceitável. risco se o método contraceptivo for usado.'
            },

          },
          id: '4'
        },
        point_5: {
          title: 'Certeza nas evidências',
          intro:
            'Certeza de que uma estimativa de associação ou efeito está correta ou, melhor ainda, que um efeito verdadeiro está em um lado de um limite especificado ou dentro de um intervalo escolhido. A certeza da evidência, também chamada de confiança nas estimativas de efeitos, qualidade da evidência, força da evidência ou níveis de evidência, é expressa em quatro categorias: alta, moderada, baixa e muito baixa, avaliada pelas por perguntas norteadoras para um corpo de evidências (entre aspas os domínios específicos abordados pelos cientistas):',
          text: {
            0: {
              id: '0',
              content:
                'Os estudos de pesquisa são bem feitos? "Limitações no detalhamento do desenho e execução do estudo, também conhecimento como risco de viés ou validade interna'
            },
            1: {
              id: '1',
              content:
                'Os resultados são consistentes entre os estudos? "inconsistência'
            },
            2: {
              id: '2',
              content:
              'Quão direta é a relação dos resultados com a nossa pergunta? "Comparações indiretas, aplicabilidade, generalização, validade externa'
          },
            3: {
              id: '3',
              content:
              'O tamanho do efeito é preciso - devido a um erro aleatório? "Indefinição'
           },
            4: {
              id: '4',
              content:
              'Esses são todos os estudos que foram feitos? "Viés de publicação'
           },
            5: {
              id: '5',
              content:
                'Há mais alguma coisa que nos deixe particularmente seguros? "Efeitos de grande magnitude, preditores de pior caso e conclusões ainda fortes, relação exposição-efeito'
            },

          },
          id: '5'
        },
        point_6: {
          title: 'Certeza na estimativa do efeito',
          content:
            'Isso expressa a certeza no efeito de uma intervenção ao considerar o conjunto de evidências e é semelhante à certeza da evidência.',
          id: '6'
        },
        point_7: {
          title: 'Mapa de recomendações',
          content:
            'Uma ferramenta para organizar recomendações a partir de um conjunto de diretrizes para uma determinada condição, relatar características em torno das evidências e julgamentos que informam a recomendação e destacar clusters e lacunas na disponibilidade de recomendações, em um esquema visual digital. Os mapas de recomendação estão organizados pelos componentes da questão PICO (População, Intervenções, Comparador, Resultado) que orientaram o desenvolvimento da respectiva recomendação.',
          id: '7'
        },
        point_8: {
          title: 'Glossário do Manual do GRADE',
          content:
            'https://gdt.gradepro.org/app/handbook/translations/es/handbook.html#h.20xfydz',
          id: '8'
        }
      },
      doc_title: 'BIGG-REC - Recomendaciones GRADE'
    },
    map: {
      recommendations_map: 'Mapa de recomendações',
      back_button: 'Voltar atrás'
    },
    footer: {
      powered: 'Desenvolvido por',
      privacy_policy: {

        content_1: 'example {link}',
        link: 'url@gmail',
        main_title: 'Política de Privacidade'
      },
      contact: {
        name: 'Contato',
        text:"Para perguntas ou consultas, entre em contato conosco em",
        content: {
          title_1: 'Exemplo de Título'
        }
      },
      terms_and_conditions: {
        text: [
          {
            id: '0',
            content:
              'Content of this site or linked by Epistemonikos Database ("Epistemonikos") to other sites is provided in a free and open way, without contractual link created or existing between the owners and/or administrators of this website and the project\'s participants, and users. Content owned by Epistemonikos that is made available to users can be freely used, subject to the terms and conditions of its licensing scheme. Content from third parties linked to, can be used in accordance with the licensing requirements of the rights owner of the linked content.'
          },
          {
            id: '1',
            content:
              'Only the English version of all the titles and abstracts of available documents, as well as translations marked with a star can be considered reliable. However, regarding all documents and translations, Epistemonikos, its collaborators, administrators, programmers, sponsors, researchers and in general anyone related to Epistemonikos or that works in the project, do not act as guarantors nor are in any way obligated or liable regarding the exactitude, completeness, or use of the provided information, which is owned by their corresponding rights holders.'
          },
          {
            id: '2',
            content:
              'Epistemonikos does not support, endorse or recommend any commercial product, treatment or service. The points of view and opinions of individual authors of the information that is made available do not necessarily reflect the opinion of Epistemonikos, nor of its collaborators, administrators, programmers, sponsors, researchers, and in general of any person related to Epistemonikos or that works in the project. Therefore, they cannot be used for commercial purposes or in order to support a specific product, treatment or service.'
          },
          {
            id: '3',
            content:
              'Epistemonikos does not provide specific health advice, consulting or recommendations but instead seeks to provide to the general public, in a systematized form, the best and most reliable available information so as to better understand the problems of healthcare. Epistemonikos does not assume any legal obligation or liability derived from the application of the information to real situations. The information made available to the public by Epistemonikos, especially about pharmacotherapy and surgical procedures, does not in any way substitute, replace or complement advice provided by doctors or other healthcare professionals. Any use of the information contained in Epistemonikos is the sole responsibility of the user.'
          },
          {
            id: '4',
            content:
              'Epistemonikos.org is a collaborative nonprofit project maintained by Epistemonikos Foundation. All of its content and features are available for free.'
          }
        ],
        intellectual_property: {
          text: [
            {
              id: '0',
              content:
                'Epistemonikos links to contents located in other websites and databases, mainly serialized scientific journals. Said links provide access to content owned by third parties, when allowed by them. Content located in linked sites are in no way amended or modified. Copyright of the content to which Epistemonikos links is owned by their respective rights holders.'
            },
            {
              id: '1',
              content:
                'For better reference, and for purposes of research and academia, Epistemonikos can provide fragments of the linked content, including a direct reference to the source, title and author.'
            },
            {
              id: '2',
              content:
                'Any trademark, whether for products, services, composite marks, collective marks, industrial designs, patents or other intellectual property rights than can be addressed, commented on or cited in Epistemonikos’ content, or content linked to, are owned by their respective rights holders. Unless expressly stated, Epistemonikos has no relation nor contractual link to the corresponding rights holders.'
            }
          ],
          laws: [
            {
              text: [
                {
                  id: '0',
                  content:
                    'Epistemonikos is a collaborative nonprofit project that strives to publicize and ease access to evidence-based medicine knowledge. Linked information is used in compliance with the laws and international treaties in force in the Republic of Chile.'
                },
                {
                  id: '1',
                  content:
                    'If you own the copyright of a work contained or referred to in the website, and believe the Epistemonikos’ use of it is infringing, please contact contact@epistemonikos.org, stating your rights to the work, its location in the website, the rights allegedly infringed and, if applicable, the user that provides the infringing content. If justified, in compliance with Law 17.336 of Intellectual Property, the infringing content will be removed.'
                }
              ],
              name: 'Law 17.336 of Intellectual Property (Chile)'
            }
          ],
          title: 'Intellectual Property'
        },
        jurisdiction: {
          text: [
            {
              id: '0',
              content:
                'Epistemonikos publishes, links to or makes available to users, all its content in compliance with the laws of the Republic of Chile. Any controversy, conflict or claim arising from the website and its content, will be decided in a Chilean court of law, in compliance with applicable laws in force in the Republic of Chile.'
            }
          ],
          title: 'Jurisdiction'
        },
        main_title: 'Termos e Condições'
      }
    },
    modals: {
      contact: {
        title: 'Contato',
        content:
          'Para perguntas ou dúvidas sobre esta política de privacidade, entre em contato conosco em bir.online@paho.org'
      }
    },
    undefined: 'en'
  }
};

export default messages;
