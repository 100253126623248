<template>
  <div id="app" :class="{'mobile': $mq == 'mobile'}">
    <top-menu/>
    <div class="content">
      <router-view v-if="$mq != 'mobile'" name="desktop"/>
      <router-view v-if="$mq == 'mobile'" name="mobile"/>
    </div>
    <div v-if="$route.name != 'TopicsList'">
      <BackToTopButton/>
    </div>
    <footer-menu/>
  </div>
</template>

<script>

import TopMenu from './components/TopMenu'
import Footer from './components/Footer'
import BackToTopButton from './components/utils/BackToTopButton'
import {mapMutations} from 'vuex'

export default {
  name: 'App',
  components: {
    'top-menu': TopMenu,
    'footer-menu': Footer,
    BackToTopButton
  }, 
  created () {
    this.checkDocument()
    this.setLanguage()
  },
  watch: {
    '$i18n.locale': function () {
      document.title = this.$t('general.doc_title')
    }
  },
  methods: {
    ...mapMutations(['SET_LANGUAGE']),     
    checkDocument () {
    if (typeof document !== 'undefined') {
      document.title = this.$t('general.doc_title')
      }
    },
    setLanguage () {
      const currentLanguage = localStorage.getItem('lang')
      currentLanguage ? this.SET_LANGUAGE(currentLanguage) : this.SET_LANGUAGE('en')
    }
  }
}
</script>

<style lang="scss">
  @import "./assets/styles/main.scss";

  @font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: normal;
    src: local("Lato"), url(./fonts/Lato-Regular.ttf) format('ttf');;
  }

  @font-face {
    font-family: 'Frutiger';
    font-style: normal;
    font-weight: normal;
    src: local("Frutiger"), url(./fonts/Frutiger.woff) format('woff');;
  }


  @font-face {
    font-family: 'Frutiger Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Frutiger Bold') , url(./fonts/Frutiger_bold.woff) format('woff');;
  }

  // @font-face {
    // font-family: "Frutiger";
    // src: local("Frutiger"), url(./fonts/FTB.ttf) format("truetype");
  // }
  @font-face {
    font-family: "Epistemonikos";
    src: local("Epistemonikos"),
      url(./fonts/font_epistemonikos.woff) format("truetype");
  }
  body {
    font-family: "Frutiger", sans-serif !important;
    color: $main-font !important;
  }
  html {
  scroll-behavior: smooth;
}
</style>
