<template>
  <div class="home-footer pt-4 pb-2">
      <div class="container  d-lg-flex justify-content-between align-items-start my-3">
        <div>
           <ul class="list-unstyled my-0">
              <li><router-link :to="{name: 'TermsConditions', params: {lang: $i18n.locale}}">{{$t("footer.terms_and_conditions.main_title")}}</router-link></li>
              <li><router-link :to="{name: 'PrivacyPolicy', params: {lang: $i18n.locale}}">{{$t("footer.privacy_policy.main_title")}}</router-link></li>
              <li v-if="true"><router-link to="">
                <span v-b-modal.contact_modal>{{$t("footer.contact.name")}}</span>
              </router-link></li>
              <li v-else><a :href="`javascript:void(window.open('//contacto.bvsalud.org/chat.php?group=Atendimento&ptl=${$i18n.locale}&hcgs=MQ__&htgs=MQ__&hinv=MQ__&hfk=MQ__%27,%27%27,%27width=400,height=600,left=0,top=0,resizable=yes,menubar=no,location=no,status=yes,scrollbars=yes%27))`">Contacto</a></li>
            </ul>
        </div>
        <div>
          <a :href="`https://www.paho.org/${$i18n.locale}`" target="_blank" >
              <img v-show="$i18n.locale == 'es'" class="ops-logo img-fluid" :src="require('../assets/images/ops_logo_es_white.png')" alt="ops-logo">
              <img v-show="$i18n.locale == 'en'" class="ops-logo img-fluid" :src="require('../assets/images/ops_logo_en_white.png')" alt="ops-logo">
          </a>
        </div>
        <div>
          <p class="m-0">{{$t('footer.powered')}}</p>
          <a :href="`https://foundation.epistemonikos.org/`" target="_blank"><img class="episte-logo img-fluid" src="../assets/images/logo_epistemonikos_text_white.svg" onerror="this.onerror=null; this.src='../assets/images/logo_epistemonikos_text.png'" alt="Licence Creative Commons"></a>
        </div>
      </div>
      <b-modal id="contact_modal" :title="$t('modals.contact.title')" cancel-disabled>
        <p class="my-2">
          <template >
            <p>{{ $t("footer.contact.text") }} <a href="mailto:bir.online@paho.org">bir.online@paho.org</a></p>
          </template>
         
        </p>
        <template #modal-footer="{ ok }">
          <b-button size="sm" variant="success" @click="ok()">
            OK
          </b-button>
        </template>
      </b-modal>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data () {
    return {
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/variables.scss";
  .home-footer {
    color: $white;
    background: $ops_portal;
      .row {
      font-size: 0.8em;
      color: $green-font;
    }
    a, p {
      color: $white;
    }
    .episte-logo {
      height: 40px; 
    }
  }
</style>
