import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store/index';
import i18n from '../i18n';
// import Home from '../views/Home.vue'
const TopicsList = () =>
  import(/* webpackChunkName: "list" */ '@/components/TopicsList');
const Guideline = () =>
  import(/* webpackChunkName: "list" */ '@/components/Guideline');
const RecommendationsMain = () =>
  import(/* webpackChunkName: "list" */ '@/components/RecommendationsMain');
const Recommendations = () =>
  import(/* webpackChunkName: "list" */ '@/components/Recommendations');
const RecommendationsMap = () =>
  import(/* webpackChunkName: "list" */ '@/components/RecommendationsMap');
const About = () => import(/* webpackChunkName: "list" */ '@/components/About');
const Glossary = () =>
  import(/* webpackChunkName: "list" */ '@/components/Glossary');
const TermsConditions = () =>
  import(/* webpackChunkName: "list" */ '@/components/TermsConditions');
const PrivacyPolicy = () =>
  import(/* webpackChunkName: "list" */ '@/components/PrivacyPolicy');
const NotFound = () =>
  import(/* webpackChunkName: "list" */ '@/components/NotFoundView');

Vue.use(VueRouter);

const supported_locales = ['en', 'es', 'pt', 'fr'];
// const supported_locales = ['en', 'es', 'pt'];
const routes = [
  // {
  //   path: '/',
  //   redirect: '/'
  // },
  {
    path: '/:lang?/',
    name: 'TopicsList',
    components: {
      desktop: TopicsList,
      mobile: TopicsList
    }
  },
  {
    path: '/:lang?/recommendations',
    name: 'RecommendationsMain',
    components: {
      desktop: RecommendationsMain,
      mobile: RecommendationsMain
    }
  },
  {
    path: '/:lang?/map/:id',
    name: 'RecommendationsMap',
    components: {
      desktop: RecommendationsMap,
      mobile: RecommendationsMap
    }
  },
  {
    path: '/:lang?/guidelines/:id',
    name: 'Guideline',
    components: {
      desktop: Guideline,
      mobile: Guideline
    }
  },
  {
    path: '/:lang?/recommendations/:id',
    name: 'Recommendations',
    components: {
      desktop: Recommendations,
      mobile: Recommendations
    }
  },
  {
    path: '/:lang?/about',
    name: 'About',
    components: {
      desktop: About,
      mobile: About
    }
  },
  {
    path: '/:lang?/glossary',
    name: 'Glossary',
    components: {
      desktop: Glossary,
      mobile: Glossary
    }
  },
  {
    path: '/:lang?/terms-and-conditions',
    name: 'TermsConditions',
    components: {
      desktop: TermsConditions,
      mobile: TermsConditions
    }
  },
  {
    path: '/:lang?/privacy-policy',
    name: 'PrivacyPolicy',
    components: {
      desktop: PrivacyPolicy,
      mobile: PrivacyPolicy
    }
  },

  {
    path: '*',
    name: 'NotFound',
    components: { desktop: NotFound, mobile: NotFound }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const locale = to.params.lang;
  if (!supported_locales.includes(locale)) {
    i18n.locale = localStorage.getItem('lang')
      ? localStorage.getItem('lang')
      : 'en';
    return next({ name: to.name, params: { lang: i18n.locale } });
  }
  i18n.locale = locale;
  return next();
});
router.afterEach((to) => {
  i18n.locate = to.params.lang;
  localStorage.setItem('lang', i18n.locale);
  store.commit('SET_LANGUAGE', to.params.lang);
});

export default router;
