<template>
  <div class="top-menu">
    <b-navbar
      id="ops-portal-navbar"
      class="flex-column py-md-0"
      toggleable="md"
      type="dark"
    >
      <b-container fluid class="w-100 p-0 pt-1 d-none d-md-block">
        <div class="d-flex justify-content-end container px-4 pt-2 d-none d-md-flex">
          <lang-selector />
        </div>
      </b-container>
      <b-container
        class="pb-md-4 pt-md-2 justify-content-md-center justify-content-md-between"
      >
        <b-navbar-brand :to="{ name: 'TopicsList', params: { lang: $i18n.locale } }">
          <img
            class="d-none d-md-inline img-fluid ops-logo mr-3"
            :src="require(`../assets/images/bvs_logo_${$i18n.locale}.svg`)"
            alt=""
          />
          <img
            class="img-fluid bigg-rec-logo"
            :src="require(`../assets/images/bigg_rec_logo.svg`)"
            alt="bigg-rec-logo"
          />
        </b-navbar-brand>
        <img
          class="d-none d-md-inline img-fluid ops-logo"
          style="max-width: 450px"
          :src="require(`../assets/images/ops_logo_${$i18n.locale}.svg`)"
          alt="ops-logo"
        />
        <div class="navbar-dark d-md-none">
          <b-navbar-toggle
            target="nav_collapse"
            class="ms-3 my-2 my-md-0 border-white text-white"
          >
            <template v-slot:default>
              <div>
                <i class="fas fa-bars"></i>
              </div>
            </template>
          </b-navbar-toggle>
        </div>
      </b-container>
    </b-navbar>
    <b-container id="ops-small-navbar" fluid class="px-0">
      <b-navbar class="container px-0 py-0 mx-auto" toggleable="md">
        <b-collapse is-nav id="nav_collapse" class="py-3 py-md-0">
          <b-navbar-nav>
            <b-button
              variant="link"
              size="sm"
              class="rounded-0 text-left"
              :to="{ name: 'TopicsList', params: { lang: $i18n.locale } }"
              :active="$route.name === 'TopicsList'"
              >{{ $t("top_menu.home") }}
            </b-button>
            <b-button
              variant="link"
              size="sm"
              class="rounded-0 text-left"
              :to="{ name: 'About', params: { lang: $i18n.locale } }"
              :active="$route.name === 'About'"
              >{{ $t("top_menu.about") }}</b-button
            >
            <b-button
              variant="link"
              size="sm"
              class="rounded-0 text-left"
              :to="{ name: 'Glossary', params: { lang: $i18n.locale } }"
              :active="$route.name === 'Glossary'"
              >{{ $t("top_menu.glossary") }}</b-button
            >
            <div class="d-flex d-md-none ml-2 mt-3 lang">
              <div v-for="lang in langs" :key="lang.value">
                <b-button
                  size="sm"
                  variant="link"
                  class="border p-0 px-2"
                  :class="{ active: $i18n.locale === lang.value }"
                  @click="setLang(lang.value)"
                >
                  {{ lang.value }}
                </b-button>
              </div>
            </div>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </b-container>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { allowLangs } from "../i18n";
import LangSelector from "./LangSelector.vue";
export default {
  components: { LangSelector },
  name: "TopMenu",
  computed: {
    ...mapState(["language"]),
    langs() {
      return allowLangs;
    },
  },
  methods: {
    ...mapMutations(["SET_LANGUAGE"]),
    setLang(lang) {
      this.$i18n.locale === lang;
      const route = this.$route;
      if (this.language !== lang) {
        this.SET_LANGUAGE(lang);
        this.$router.replace(
          "/" +
            lang +
            "/" +
            route.fullPath.split("/").slice(2, route.fullPath.length).join("/")
        );
      }
    },
  },
};
</script>

<style lang="scss" scope>
@import "../assets/styles/variables.scss";
.top-menu {
  background-color: $ops_portal;
}
#ops-portal-navbar {
  min-height: 40px;
  background-color: $ops_portal;
  border-color: $ops_portal;
  .ops-logo {
    height: 70px;
  }
  .navbar-brand {
    padding-top: 0px;
    padding-bottom: 0px;
    h1 {
      margin-bottom: 0px;
    }
    .bigg-rec-logo {
      height: 40px;
      margin: 4px 0;
      background-color: white;
      &:hover {
        box-shadow: 1px 1px 5px #565656;
      }
    }
  }
}
#ops-small-navbar {
  background-color: $breadcrumb;
}

#ops-small-navbar,
#ops-language-navbar {
  .btn {
    background-color: $breadcrumb;
    color: $black;
  }
  .btn.active {
    background-color: #acc6ff;
    text-decoration: none;
  }
  .btn.disabled {
    opacity: 1;
    color: $txt;
  }
  .btn:hover {
    text-decoration: none;
  }
}
@media screen and (max-width: 768px) {
  #ops-small-navbar {
    background-color: $ops-portal;
    .lang {
      .btn {
        &:first-child {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
        &:last-child {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
        &.active {
          color: black;
          background-color: white;
        }
      }
    }
    .btn {
      color: white;
      background-color: transparent;
      padding-bottom: 0px;
    }
    .btn:hover {
      text-decoration: underline;
    }
  }
}
</style>
