import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messages from './messages.js';
Vue.use(VueI18n);

const allowLangs = [
  { label: 'English', value: 'en' },
  { label: 'Spanish', value: 'es' },
  { label: 'Français', value: 'fr' },
  { label: 'Português', value: 'pt' }
];

// Create VueI18n instance with options
let locale_lang = localStorage.getItem('lang');
if (!allowLangs.map((el) => el.value).includes(locale_lang)) {
  locale_lang = 'en';
}
const i18n = new VueI18n({
  locale: locale_lang, // set locale
  messages, // set locale messages
  silentFallbackWarn: true
});

export default i18n;
export { allowLangs };
// Create a Vue instance with `i18n` option
// new Vue({ i18n }).$mount('#app')
